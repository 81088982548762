.buy-sell-form {
  padding: 16px 16px 24px;
  width: 100%;
  position: relative;
  .row:not(.no-padding) {
    padding: 16px 0;
  }
  .form-container {
    &.--with-min-height {
      min-height: 146px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      &.--with-min-height {
        min-height: 125px;
      }
    }
  }
  .currency {
    box-shadow: 0 0 15px 0 var(--color-shadow);
    padding: 8px 16px;
    display: flex;
    border-radius: 2px;

    flex-direction: column;
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
    &-logo {
      border-bottom: 1px solid var(--color-divider);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &-image {
        min-height: 80px;
        min-width: 80px;
      }
      &-name {
        color: var(--color-text-l1);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2em;
        padding: 4px 0;
        text-align: center;
      }
      border-bottom: 1px solid var(--color-divider);
    }
    &-price {
      display: flex;
      justify-content: center;
      &-title {
        font-size: 12px;
        font-weight: 600;
        padding: 4px 0;
        color: var(--color-text-l2);
      }
    }
  }
  .info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 12px;
    border-radius: 4px;
    justify-content: space-between;
    .item {
      display: flex;
      padding: 0 4px;
      align-items: flex-end;
      flex-wrap: wrap;
      justify-content: center;
      &:first-child {
        align-items: flex-start;
      }
      .name {
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: var(--color-text-l3);
        padding-right: 4px;
      }
      .value {
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.4px;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: relative;
        span {
          padding-right: 2px;
        }
      }
    }
  }
}
