.transact-table {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    width: 100%;
  }
  &-confirmation {
    &-warning {
      display: flex;
      justify-content: center;
    }
    &-title {
      display: flex;
      justify-content: center;
      margin: 8px 0 14px;
      &-text {
        font-size: 16px;
        text-align: center;
        color: var(--color-red);
        font-weight: 700;
      }
    }
    &-message {
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 4px;
      background: var(--color-divider);
      padding: 14px;
      cursor: pointer;
      &-text {
        flex-grow: 1;
        &,
        a {
          font-size: 12px;
          letter-spacing: 0.25px;
          font-weight: 500;
        }
      }
      &-chevron {
        flex-shrink: 0;
      }
    }
    &-check {
      display: flex;
      border-top: 1px solid var(--color-divider);
      .checkbox {
        margin: 14px 0;
      }
      &-label {
        font-size: 12px;
        font-weight: 600 !important;
      }
    }
  }
  &-wrapper {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 16px;
    width: 100%;
    border: 1px solid var(--color-divider);
  }
  &-box {
    background-color: var(--color-background-default);
    margin-bottom: 0px;
    width: 100%;
    padding: 0 1px 16px;
    border-collapse: separate;
    border-spacing: 0;
    thead {
      > tr {
        box-shadow: 0 1px 2px 0 var(--color-divider);
        background: var(--color-background-l1);
        th {
          vertical-align: middle;
          color: var(--color-text-l2);
          font-size: 10px;
          line-height: 1.2;
          font-weight: bold;
          padding: 8px 14px !important;
          border-bottom: 1px solid var(--color-divider);
          text-transform: uppercase;
          &:first-child {
            padding: 2px 2px 2px 16px;
          }
        }
      }
    }
    tbody > tr.record {
      box-shadow: 0 1px 2px 0 var(--color-divider);
      &.active {
        td {
          background-color: var(--color-background-l0);
          border-top: 1px solid var(--color-divider);
        }
      }
      &:nth-child(even) {
        background: var(--color-table-row-even);
      }
      &:nth-child(odd) {
        background: var(--color-table-row-odd);
      }
      &:hover {
        background: var(--color-table-row-hover);
      }
      td {
        vertical-align: middle;
        color: var(--color-text-l1);
        padding: 10px 14px;
        font-size: 12px;
        &:first-child {
          padding: 2px 2px 2px 16px;
          text-align: left;
        }
        &:not(:nth-child(2)) {
          text-align: right;
        }

        &.align-top {
          vertical-align: top;
        }
      }
    }
    tbody > tr.spacer {
      background: var(--color-background-default);
      display: table-row;
      td {
        background: var(--color-background-default);
      }

      &.top-spacer {
        td {
          padding: 4px 0;
          border-top: 1px solid var(--color-divider);
        }
      }
    }
    tbody > tr.form-row {
      td {
        background: var(--color-background-default);
      }
      + tr.record {
        td {
          border-top: 1px solid var(--color-divider);
        }
      }
    }
  }
}
