.xid-order-book {
  background-color: var(--color-background-l0);
  margin-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  left: 0;

  thead {
    tr {
      height: 20px;
      th {
        height: 20px;
        color: var(--color-text-l2);
        font-weight: bold;
        font-size: 10px;
        line-height: 1.2;
        border-bottom: 1px solid var(--color-divider);
        padding: 4px 14px;
        white-space: nowrap;
        &.right {
          text-align: left;
        }
      }
    }
  }
  tbody {
    tr {
      height: 30px;
      cursor: pointer;
      .filler {
        position: absolute;
        height: 30px;
      }
      td {
        font-size: 12px;
        padding: 2px 14px;
        border: none;
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: 0.4px;
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
          display: inline-block;
          width: 100%;
        }
        .status-dot {
          display: inline-block;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          &.green {
            background-color: var(--color-green);
          }
          &.red {
            background-color: var(--color-red);
          }
        }
      }
    }
    &.buy {
      tr {
        .filler {
          background: var(--color-background-success);
          left: 0%;
        }
        td {
          text-align: left;
          &:first-child {
            color: var(--color-green);
          }
          &:nth-child(2) {
            color: var(--color-text-l1);
          }
        }
        &:hover {
          td {
            background-color: var(--color-table-row-hover);
          }
        }
      }
    }
    &.sell {
      tr {
        .filler {
          background: var(--color-background-danger);
          left: 0%;
        }
        td {
          text-align: left;
          &:first-child {
            color: var(--color-red);
          }
          &:nth-child(2) {
            color: var(--color-text-l1);
          }
        }
        &:hover {
          td {
            background-color: var(--color-table-row-hover);
          }
        }
      }
    }
  }
}
