.hv-modal,
div#HV_loader_popup {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--color-background-modal) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 100 !important;
  transform: none !important;
}

.hv-modal-content {
  overflow: hidden !important;
  box-shadow: 0 4px 8px 0 var(--color-shadow) !important;
  visibility: visible !important;
  position: relative !important;
  transform: none !important;
  top: auto !important;
  left: auto !important;
  height: auto !important;
  width: 368px !important;
  padding: 0 !important;
  border-radius: 4px !important;
  background-color: var(--color-background-l0) !important;
  @media screen and (max-width: 767px) {
    width: 100% !important;
    height: 100% !important;
  }

  & > center {
    font-family: inherit !important;
  }

  & > div {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
  }
  .hv-center-element {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    font-family: inherit !important;
    > .hv-title {
      font-size: 16px !important;
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      margin-right: 0 !important;
      border-bottom: 1px solid var(--color-divider) !important;
      line-height: 1em !important;
      margin-bottom: 0 !important;
      text-align: left !important;
      padding-left: 16px !important;
      color: var(--color-text-l1) !important;
    }
    > .hv-divforcover {
      position: relative !important;
      margin: 0 !important;
      bottom: auto !important;
      width: auto !important;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-grow: 1;
    }
  }
}

.hv-loader-box {
  background: var(--color-background-l0);
  padding: 16px;
  box-shadow: 0 4px 8px 0 var(--color-shadow) !important;
  border-radius: 4px !important;
}

.hv-upload-btn,
.hv-capture-btn {
  width: 280px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  margin: 0 !important;
  padding: 12px 16px !important;
  border-radius: 4px !important;
  background-color: var(--color-green) !important;
  text-transform: uppercase;
  max-width: 280px !important;
  > img {
    display: none;
  }
}

.hv-upload-btn {
  margin-bottom: 16px !important;
}

.hv-modal-footer {
  position: relative !important;
  height: auto !important;
  margin: auto !important;
  padding: 12px 0 !important;

  .footertext {
    color: var(--color-text-l2) !important;
  }
}

.hv-close-button {
  float: none !important;
  width: auto !important;
  line-height: normal !important;
  text-align: center !important;
  cursor: pointer !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  font-size: 32px !important;
  margin-top: 0 !important;
  font-weight: 400 !important;
  position: absolute !important;
  top: 2px !important;
  right: 16px !important;
}

div#hv-face-instruction-screen {
  .hv-instruction-div {
    padding: 16px !important;
    height: auto !important;
    margin-bottom: 8px !important;
    > .hv-instruction-small-div-face-text {
      text-align: left !important;
      grid-column-gap: 16px !important;
      > .hv-instruction-tick {
        height: 21px !important;
        width: 21px !important;
      }
    }
    > .hv-instruction-selfie {
      margin-top: 0 !important;
      padding: 16px !important;
      height: auto !important;
      > .hv-selfie-img {
        width: 67% !important;
        height: 100% !important;
        max-height: 300px !important;
        max-width: 300px !important;
        min-height: 160px !important;
      }
    }
    > .hv-instruction-props {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 16px;
      grid-column-gap: 16px;
      .hv-instruction-props-text {
        font-size: 14px !important;
      }
    }
  }
}

div#hv-face-capture-screen {
  .hv-instructions {
    display: none;
  }
  .hv-divforcover {
    position: relative;
    margin-top: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    bottom: auto !important;
    @media screen and (min-height: 600px) {
      margin-top: 24px !important;
    }
  }

  div#hv-cameraholder {
    margin-top: 24px;
    @media screen and (min-width: 767px) {
      min-height: 312px;
    }
  }

  .hv-bottom-instructions {
    font-size: 14px !important;
    margin-top: 24px !important;
    min-height: 48px !important;
    padding: 24px !important;
  }

  div#hv-cameraLoadingText {
    display: none;
  }
}

div#hv-doc-instruction-screen {
  .hv-instruction-div {
    max-width: 100% !important;
    height: auto !important;
    padding-bottom: 16px !important;
    div {
      position: relative;
      padding: 16px;
      max-width: 100% !important;
      height: auto !important;
    }
    > :first-child {
      display: flex !important;
      flex-direction: column !important;
      border-bottom: 1px solid var(--color-divider);
      .hv-instruction-img-left {
        width: 50% !important;
        max-width: 50% !important;
        min-height: 100px !important;
      }
      .hv-instruction-doc-text {
        width: 80% !important;
        padding-bottom: 0 !important;
      }
      .hv-instruction-tick {
        position: absolute !important;
        right: calc(25% - 4px) !important;
        top: 6px !important;
        height: 24px !important;
        width: 24px !important;
      }
    }
    > :nth-child(2) {
      display: flex !important;
      flex-direction: row-reverse !important;
      .hv-instruction-doc-text,
      .hv-instruction-img-left {
        width: 50% !important;
        max-width: 50% !important;
      }

      .hv-instruction-img-left {
        min-height: 100px !important;
      }

      .hv-instruction-doc-text {
        text-align: left;
      }
      .hv-instruction-tick {
        position: absolute;
        left: calc(50% - 12px);
        top: 4px;
        height: 24px !important;
        width: 24px !important;
      }
    }
    > :nth-child(3) {
      display: flex !important;
      flex-direction: row !important;
      .hv-instruction-doc-text,
      .hv-instruction-img-left {
        width: 50% !important;
        max-width: 50% !important;
      }

      .hv-instruction-img-left {
        min-height: 100px !important;
      }

      .hv-instruction-doc-text {
        text-align: left;
      }
      .hv-instruction-tick {
        position: absolute;
        left: calc(50% - 12px);
        top: 4px;
        height: 24px !important;
        width: 24px !important;
      }
    }
  }
  .hv-divforcover {
    padding: 0 16px !important;
    .hv-proceed-btn {
      width: 100% !important;
      font-weight: bold !important;
      font-size: 14px !important;
      padding: 12px 16px !important;
      border-radius: 4px !important;
      text-transform: uppercase;
      margin: 0 !important;
      max-width: 100% !important;
      color: var(--color-white);
      background-color: var(--color-green);
      height: auto !important;
      > img {
        display: none;
      }
    }
  }
}
div#hv-doc-instruction-screen,
div#hv-face-instruction-screen {
  .hv-divforcover {
    padding: 0 16px !important;
    .hv-proceed-btn {
      width: 100% !important;
      font-weight: bold !important;
      font-size: 14px !important;
      padding: 12px 16px !important;
      border-radius: 4px !important;
      text-transform: uppercase;
      margin: 0 !important;
      max-width: 100% !important;
      color: var(--color-white);
      background-color: var(--color-green);
      height: auto !important;
      > img {
        display: none;
      }
    }
  }
}

div#hv-doc-capture-screen {
  .hv-bottom-instructions {
    padding: 14px !important;
    color: var(--color-text-l2) !important;
    font-size: 14px !important;
    margin: 16px 0 !important;
  }
  div#hv-cameraLoadingText {
    display: none;
  }
  div#hv-cameraholder {
    margin-top: 16px !important;
  }
}

div#hv-doc-capture-review-screen {
  .hv-bottom-instructions {
    padding: 16px !important;
    margin-top: 24px !important;
    color: var(--color-text-l2) !important;
    font-size: 14px !important;
  }
  .hv-review-buttons {
    display: flex !important;
    flex-direction: column !important;
    height: auto !important;
    align-items: center !important;
    padding: 12px 24px !important;
    .hypervergebtn,
    .hv-proceed-btn {
      width: 100% !important;
      font-weight: bold !important;
      font-size: 14px !important;
      margin: 8px !important;
      padding: 12px 16px !important;
      border-radius: 4px !important;
      text-transform: uppercase;
      max-width: 100% !important;
      height: auto !important;
      > img {
        display: none;
      }
    }
    .hv-retake-btn {
      background-color: var(--color-white);
      color: var(--color-green) !important;
      border: 1px solid var(--color-green) !important;
      line-height: normal !important;
    }
    .hv-use-this-photo-btn {
      color: var(--color-white) !important;
      background-color: var(--color-green) !important;
      line-height: normal !important;
    }
  }

  img.hv-preview-video {
    max-height: 300px !important;
    object-fit: cover !important;
    object-position: 50% 50% !important;
    border-radius: 0px !important;
  }

  div#hv-cameraholder {
    margin-top: 16px !important;
  }
}

div#hv-doc-user-choice-screen {
  .hv-user-choice-screen-title {
    margin-top: 48px !important;
    padding: 16px !important;
    border-top: 1px solid var(--color-divider);
    font-weight: bold;
    font-size: 14px;
    color: var(--color-text-l2);
    max-width: none;
  }
  .hv-modal-footer {
    margin: 0 !important;
    position: relative !important;
    padding: 12px 0 !important;
    bottom: auto !important;
  }
}

div#hv-doc-upload-review-screen {
  .hv-instructions {
    display: none;
  }
  .hv-preview-img {
    width: 100% !important;
    height: auto;
    padding: 16px;
    max-height: 300px !important;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .hv-bottom-instructions {
    padding: 14px !important;
    color: var(--color-text-l2) !important;
    font-size: 14px !important;
    margin: 16px 0 !important;
  }
  .hypervergebtn {
    width: 100% !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin: 0 !important;
    padding: 12px 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase;
    max-width: 100% !important;
    height: auto !important;

    > img {
      display: none;
    }
  }

  .hv-reupload-btn {
    margin-bottom: 16px !important;
    line-height: normal !important;
    background-color: var(--color-white);
    color: var(--color-green) !important;
    border: 1px solid var(--color-green) !important;
  }

  .hv-use-this-photo-btn {
    line-height: normal !important;
    color: var(--color-white) !important;
    background-color: var(--color-green) !important;
  }

  .hv-divforcover {
    flex-direction: column;
    padding: 16px;
    justify-content: flex-end;
  }
}
