.xid {
  &-container {
    margin: 20px 0 0px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      margin-top: 16px;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    cursor: pointer;

    &-marker {
      font-size: 18px;
      opacity: 0.7;
    }
    &-title {
      font-weight: 700;
      font-size: 12px;
      opacity: 0.7;
    }
    &-wrapper {
      background: var(--color-background-default);
      color: var(--color-text-l1);
      padding: 4px 16px 4px 12px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      &.opened {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  &-input {
    &-link {
      padding: 0 20px 16px;
      font-size: 10px !important;
      text-transform: uppercase;
    }
    &-error {
      padding: 0 4px;
    }
  }
  &-body {
    background-color: var(--color-background-default);
    border-radius: 4px;
    border-top-left-radius: 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &-username {
      border-radius: 0 0 4px 4px;
      color: var(--color-text-l1);
      display: flex;
      padding: 12px 20px;
      align-items: center;
      border-top: 2px solid var(--color-divider);
      flex-direction: column;
      &-confirmation-message {
        display: flex;
        margin: 0 0 24px;
        align-items: center;
        background: var(--color-shadow);
        padding: 14px;
        &-list {
          margin: 0 0 0 24px;
          &-item {
            letter-spacing: 0.5px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      @media screen and (max-width: 767px) {
        justify-content: center;
      }
      &-create,
      &-share {
        display: flex;
        width: 100%;
        align-items: center;
      }
      &-share {
        justify-content: space-between;
      }
      &-intro {
        display: flex;
        width: 100%;
        padding: 8px 16px 0;
        &-list {
          margin: 0;
          opacity: 0.7;
          li {
            font-size: 12px;
            width: 100%;
          }
        }
      }
      &-label {
        font-size: 16px;
        font-weight: 700;
        padding: 0 8px 0 0;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      &-input {
        margin: 0 24px;
        input {
          text-transform: lowercase;
        }
        .mdi-close {
          &:active {
            color: var(--color-primary);
          }
        }
        &-label {
          font-size: 12px;
          opacity: 0.8;
          margin: 8px 26px 0;
        }
      }
      &-link {
        cursor: pointer;
        color: var(--color-primary);
        font-size: 12px;
        border: 1px solid var(--color-primary);
        padding: 4px;
        border-radius: 4px;
        text-transform: uppercase;
        @media screen and (max-width: 767px) {
          font-size: 10px;
        }
      }
      &-title {
        font-size: 16px;
        font-weight: 700;
        flex-grow: 1;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      &-text {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-text-l1);
        padding: 0 16px;
        &.copying {
          color: var(--color-primary);
        }
      }
    }
  }
}
