section.footer {
  background: var(--color-footer-background);
  border-top: 4px solid var(--color-primary);
  .wrapper {
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 955px) {
      max-width: 90%;
      padding: 0;
    }
    .hero {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-divider);
      align-items: center;
      padding-top: 30px;
      @media screen and (min-width: 768px) {
        padding: 45px 0 15px;
      }
      @media screen and (max-width: 860px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      .logo {
        height: 40px;
      }
      .download-apps {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        padding: 7.5px;
        @media screen and (max-width: 767px) {
          justify-content: center;
          padding-top: 22.5px;
          padding-bottom: 22.5px;
        }
        .set {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
        }
        .item {
          height: 40px;
          margin: 7.5px;
        }
      }
    }
    .menu {
      display: grid;
      grid-template-columns: repeat(auto-fill, 16%);
      grid-column-gap: 5%;
      border-bottom: 1px solid var(--color-divider);
      padding: 30px 0;
      row-gap: 30px;
      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(auto-fill, 30%);
      }
      @media screen and (max-width: 767px) {
        justify-content: center;
        grid-column-gap: 5%;
        grid-template-columns: repeat(auto-fill, 47.5%);
        &-item {
          &:nth-child(1) {
            grid-column: 1;
            grid-row: 1;
          }
          &:nth-child(2) {
            grid-column: 1;
            grid-row: 2;
          }
          &:nth-child(3) {
            grid-column: 1;
            grid-row: 3;
          }
          &:nth-child(4) {
            grid-column: 2;
            grid-row: 2;
          }
          &:nth-child(5) {
            grid-column: 2;
            grid-row: 1;
          }
        }
      }
      @media screen and (max-width: 479px) {
        grid-column-gap: 2%;
        grid-template-columns: repeat(auto-fill, 49%);
      }
      &-item {
        .title {
          font-weight: bold;
          font-size: 18px;
          line-height: 1.33em;
          color: var(--color-text-l0);
        }
        .submenu {
          padding-top: 20px;
          ul {
            list-style-type: none;
            margin-bottom: 0;
            li {
              a {
                font-size: 14px;
                line-height: 1.5em;
                color: var(--color-text-l1);
                letter-spacing: 0.5px;
                @media screen and (max-width: 479px) {
                  font-size: 14px;
                }
              }
              &:not(:last-child) {
                margin-bottom: 8px;
                @media screen and (max-width: 479px) {
                  margin-bottom: 5px;
                }
              }
              .social-icon {
                display: flex;
                align-items: center;

                .box {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 22px;
                  width: 22px;
                  min-width: 22px;
                  margin-right: 15px;
                  color: var(--color-primary);
                  @media screen and (max-width: 479px) {
                    transform: scale(0.8);
                  }
                }
              }
            }
          }
        }
      }
    }
    .company {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
      .name {
        font-size: 12px;
        font-weight: 600;
        line-height: 2;
        letter-spacing: 0.2px;
        color: var(--color-text-l1);
      }
    }
  }
}
