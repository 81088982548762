.oauth {
  &-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    flex-direction: column;
    padding: 0;
    .login-button {
      background-color: var(--color-background-l0);
      padding: 4px 16px;
      border-radius: 4px;
      min-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      margin-bottom: 2px;
      .text {
        margin-left: 10px;
        color: var(--color-binance-primary);
        font-size: 14px;
        letter-spacing: -0.025px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.9;
        letter-spacing: 1px;
      }
    }
    &-separator {
      margin: 32px 0 24px;
      background: var(--color-divider);
      height: 1px;
      width: 100%;
      position: relative;
      &:after {
        content: 'OR';
        background: var(--color-background-l0);
        position: absolute;
        top: -10px;
        left: calc(50% - 27px);
        padding: 0 15px;
        color: var(--color-text-l2);
      }
    }
  }
}
