.partnerx {
  display: inline-flex;
  flex-direction: column;
  background-color: var(--color-background-l1);
  flex-shrink: 0;
  width: 100%;
  &-section {
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 0;
    align-self: center;
    align-items: center;
    width: 75%;
    padding: 0 36px;
    @media screen and (max-width: 767px) {
      padding: 0 15px;
      width: 100%;
    }
  }
  &-hero {
    background-color: var(--color-landing-hero-background);
    min-height: 290px;
    border-bottom: 1px solid transparent;
    position: relative;
    padding: 0 20px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;

    &.logged-in {
      min-height: 220px;
      & > div:last-of-type {
        @media screen and (min-width: 768px) {
          margin: 20px auto 0px;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 60%;

      background: url(../images/partnerx-pattern-left.png) no-repeat;
      background-size: contain;
      background-position: left bottom;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 60%;
      background: url(../images/partnerx-pattern-right.png) no-repeat;
      background-position: right top;
      background-size: contain;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      flex-shrink: 0;
      flex-grow: 1;
      z-index: 3;
      @media screen and (min-width: 768px) {
        justify-content: start;
      }
    }

    &-coin-logo {
      width: 320px;
      height: 150px;
      margin: 36px 0;
      @media screen and (max-width: 767px) {
        height: 100px;
        margin: 30px 0;
      }
    }

    &-heading {
      display: flex;
      justify-content: center;
      padding: 10px 0;
      flex-shrink: 0;
      &-title {
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
        color: var(--color-white);
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
        @media screen and (max-width: 350px) {
          font-size: 20px;
        }
      }
    }
    &-bullets {
      display: flex;
      justify-content: center;
      z-index: 3;
      &-list {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 0 auto;
        &-item {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px 15px;
          flex-basis: 25%;
          @media screen and (max-width: 767px) {
            flex-basis: 50%;
            min-width: 100px;
          }
          &-text {
            padding-top: 25px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.2em;
            color: var(--color-white);
          }
        }
      }
    }
    &-description {
      display: flex;
      justify-content: center;
      padding: 10px 14px;
      flex-shrink: 0;
      max-height: 40px;
      &-title {
        font-size: 18px;
        font-weight: 700;
        opacity: 0.8;
        line-height: 20px;
        color: var(--color-white);
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          opacity: 1;
        }
        @media screen and (max-width: 350px) {
          font-size: 12px;
        }
      }
    }
  }
  &-mininfo {
    &-desc {
      z-index: 3;
      display: flex;
      justify-content: space-evenly;
      flex-shrink: 0;
      width: 100%;
      padding: 0px 20px;
      margin-top: -48px;
      @media screen and (min-width: 768px) and (max-width: 950px) {
        padding: 0px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: -60px;
        margin-bottom: -20px;
      }
      &-box {
        display: flex;
        flex-direction: column;
        width: 30%;
        //height: 160px;
        background-color: var(--color-background-l0);
        border-radius: 8px;
        box-shadow: 0px 30px 35px -18px var(--color-shadow);
        position: relative;
        padding: 20px;
        flex-shrink: 0;
        border: 1px solid var(--color-divider);
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 14px;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          box-shadow: 0px 15px 20px -10px var(--color-shadow);
        }
        .title {
          font-size: 24px;
          font-weight: 700;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
        .title.click {
          cursor: pointer;
        }
        .description {
          width: 80%;
          opacity: 0.5;
          font-size: 14px;
          flex-shrink: 0;
          flex-grow: 1;
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
        .box-icon {
          position: absolute;
          bottom: 0;
          right: 0;
          &.icon-1,
          &.icon-2,
          &.icon-3 {
            width: 55px;
            @media screen and (min-width: 768px) and (max-width: 950px) {
              width: 48px;
            }
          }
          &.icon-1 {
            bottom: -8px;
          }
          &.icon-3 {
            right: -12px;
            bottom: -5px;
          }
          &.icon-2 {
            top: 52px;
          }
        }
        .commission-tooltip .mdi-menu-down {
          position: absolute;
          font-size: 30px;
          bottom: -1px;
          right: 35px;
          height: 20px;
          @media screen and (max-width: 767px) {
            right: 42px;
          }
        }
        &-success {
          width: 25%;
          height: fit-content;
          border-left: 8px solid var(--color-green);
          border-radius: 5px;
          align-items: center;
          padding: 20px 5px;
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 20px;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            box-shadow: 0px 15px 20px -10px var(--color-shadow);
          }
          &.large {
            @media screen and (min-width: 768px) {
              width: 40%;
            }
          }
          .description {
            font-weight: 700;
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            max-height: 50px;
            z-index: 10;
          }
          .title {
            font-size: 28px;
            @media screen and (min-width: 768px) and (max-width: 950px) {
              font-size: 24px;
            }
          }
          .commission-container {
            width: calc(100% + 10px);
            border-top: 1px solid var(--color-divider);
            margin-top: 20px;
            @media screen and (max-width: 767px) {
              width: calc(100% + 40px);
            }
          }
          .commission {
            margin: 2px;
            font-size: 12px;
            padding: 4px 8px;
            border-radius: 5px;
            border: 1px solid var(--color-divider);
            white-space: nowrap;
            font-weight: 600;
            @media screen and (min-width: 768px) and (max-width: 850px) {
              font-size: 10px;
            }
          }
        }
        &-error {
          width: 100%;
          border-left: 8px solid var(--color-red);
          border-radius: 5px;
          padding: 45px;
          .title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: var(--color-red);
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 24px;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            box-shadow: 0px 15px 20px -10px var(--color-shadow);
          }
        }
      }
    }
  }
  &-login {
    position: relative;
    width: 100%;
    &-wrapper {
      z-index: 2;
      width: 100%;
      max-width: 650px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin: 0 auto 64px;
      @media screen and (max-width: 767px) {
        margin: 0 auto 48px;
      }
    }
  }
  &-sharer {
    position: relative;
    width: 100%;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
    &-wrapper {
      z-index: 2;
      width: 100%;
      max-width: 650px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin: 64px auto 0;
      @media screen and (max-width: 767px) {
        margin: 48px auto 0;
      }
    }

    &-box {
      display: flex;
      flex-direction: column;
      background-color: var(--color-background-l0);
      padding: 30px;
      width: 100%;
      box-shadow: 0 0 15px 0 var(--color-shadow);
      border-radius: 4px;
      @media screen and (max-width: 767px) {
        padding: 25px;
      }
      &-heading {
        display: flex;
        justify-content: center;
        padding: 0 0 8px 0;
        &-text {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.43;
          text-align: center;
        }
      }
      &-invite {
        margin: 8px 0;
      }
      &-buttons {
        display: flex;
        margin: 8px -5px;
        &-item {
          display: flex;
          flex-basis: 25%;
          justify-content: center;
          align-items: center;
          height: 48px;
          border-radius: 4px;
          font-size: 18px;
          &.visible-xs-flex {
            display: none;
            @media screen and (max-width: 767px) {
              display: flex;
            }
          }
          &.hidden-xs-flex {
            display: flex;
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
          color: var(--color-white);
          margin: 0 5px;
          &.item-facebook {
            background: var(--color-vendors-facebook);
          }
          &.item-whatsapp {
            background: var(--color-vendors-whatsapp);
          }
          &.item-telegram {
            background: var(--color-vendors-telegram);
          }
          &.item-twitter {
            background: var(--color-vendors-twitter);
          }
        }
      }
      &-code {
        display: flex;
        justify-content: center;
        margin: 8px 0 0;
        flex-shrink: 0;
        &-text {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
  &-faq {
    background: var(--color-background-l0);
    width: 100%;
    display: flex;
    &-wrapper {
      width: 100%;
      max-width: 650px;
      margin: 64px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 0;
      flex-shrink: 0;
      @media screen and (max-width: 767px) {
        margin: 48px auto;
      }
    }
    &-help {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 16px 0;
      flex-shrink: 0;
      &,
      a {
        font-size: 16px;
      }
      @media screen and (max-width: 767px) {
        padding: 8px 0;
        &,
        a {
          font-size: 14px;
        }
      }
    }
    &-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      &-heading {
        display: flex;
        justify-content: center;
        padding: 8px 0;
        flex-shrink: 0;
        &-title {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        }
      }
      &-panel {
        &-group {
          width: 100%;
        }
        &-item {
          border-radius: 4px;
          border: solid 1px var(--color-divider);
          margin: 5px 0;
          &-title a {
            display: flex;
            padding: 25px;
            flex-grow: 1;
            justify-content: space-between;
            color: var(--color-text-link);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            flex-shrink: 0;
          }
          &-body {
            font-size: 14px;
            padding: 0 25px 25px;
            color: var(--color-text-l1);
            line-height: 20px;
          }
        }
      }
    }
  }
  &-statistics {
    position: relative;
    width: 100%;
    &-wrapper {
      z-index: 2;
      width: 100%;
      max-width: 650px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin: 64px auto;
      @media screen and (max-width: 767px) {
        margin: 48px auto;
      }
    }

    &-table {
      width: 100%;
      max-height: 500px;
      overflow-y: auto;
      table {
        margin-bottom: 0 !important;
        > thead > tr,
        > tbody > tr {
          background-color: var(--color-table-row-odd);
          &:not(:last-child) {
            border-bottom: 1px solid var(--color-divider);
          }
          &:nth-child(even) {
            background-color: var(--color-table-row-even);
          }
          &.total {
            background-color: var(--color-green);
            color: var(--color-white);
          }

          > th,
          > td {
            padding: 15px 20px;

            font-size: 14px;
            @media screen and (max-width: 767px) {
              padding: 8px 14px;
              font-size: 11px;
              white-space: nowrap;
            }
          }
          > th {
            color: var(--color-text-l1);
            text-transform: uppercase;
          }
        }
      }
    }
    &-toggle {
      width: 100%;
      &-group {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        flex-shrink: 0;
        @media screen and (max-width: 767px) {
          margin-bottom: 12px;
        }
      }
      &-item {
        flex-grow: 1;
        font-size: 18px;
        font-weight: 400;
        box-shadow: none !important;
        opacity: 0.3;
        position: relative;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
        &.active {
          opacity: 1;
          font-weight: 700;

          &:after {
            content: '';
            height: 4px;
            width: 100%;
            display: block;
            background: var(--color-primary);
            position: absolute;
            left: 0;
            border-radius: 16px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  &-hiw {
    width: 85%;
    padding: 0;
    margin: 40px auto;
    overflow: hidden;
    @media screen and (min-width: 992px) and (max-width: 1200px) {
      width: 95%;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 0 0 50px;
    }
    &-heading {
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      &-text {
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
        color: var(--color-text-l0);
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }
    }
    &-slider {
      width: 100%;
      padding: 0 15px;

      @media screen and (max-width: 767px) {
        padding: 0 30px;
      }
      counter-reset: item-ctr;
      &-item {
        &-content {
          display: flex;
          flex-shrink: 0;
          position: relative;
          padding: 40px 25px;
          background-color: var(--color-background-l0);
          width: calc(100% - 25px);
          min-height: 300px;
          box-shadow: 0 0 15px 0 var(--color-shadow);
          border-radius: 4px;
          margin: 25px 0;
          overflow: hidden;
          @media screen and (min-width: 768px) {
            margin: 25px auto;
          }
          flex-direction: column;
          &-ig {
            height: 50px;
            margin-bottom: 20px;
            display: flex;
            flex-shrink: 0;
            justify-content: center;
          }
          &-header {
            margin: 10px auto 25px;
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            &-text {
              text-align: center;
              font-weight: 700;
              font-size: 20px;
              color: var(--color-text-l1);
            }
          }
          &-description {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            &-text {
              color: var(--color-text-l2);
              line-height: 1.43em;
              text-align: center;
              font-size: 14px;
            }
          }
          &:after {
            content: counter(item-ctr);
            counter-increment: item-ctr;
            position: absolute;
            font-size: 72px;
            right: 18px;
            bottom: -12px;
            color: var(--color-text-l2);
            opacity: 0.2;
            font-weight: 700;
            line-height: 1em;
          }
        }
      }

      .slick-slide {
        &:focus {
          outline: 0;
        }
      }
      .slick-list {
        overflow: visible;

        @media all and (min-width: 992px) {
          overflow: hidden;
        }
      }
      .slick-dots {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          margin: 0 5px;

          &.slick-active {
            button {
              width: 30px;
              &:before {
                width: 30px;
                background-color: var(--color-primary);
                border-radius: 10px;
                box-shadow: 0 2px 4px 0 var(--color-shadow);
                opacity: 1;
              }
            }
          }

          button {
            position: relative;
            font-size: 0;
            line-height: 0;
            display: block;
            width: 9px;
            height: 9px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:before {
              position: absolute;
              top: 0;
              left: 0;
              width: 9px;
              height: 9px;
              content: '';
              text-align: center;
              background-color: var(--color-gray);
              border-radius: 50%;
            }
          }
        }
      }
      .slick-prev,
      .slick-next {
        position: absolute;

        background: transparent;
        border: transparent;
        transform: translateX(-50%);
        bottom: -50px;
        top: auto;
        width: 35px;
        height: 35px;
        &:before {
          content: none;
        }
        &.slick-disabled {
          opacity: 0.25;
        }
      }
      .slick-prev {
        left: calc(50% - 30px);
        background: url(../images/round-left-arrow-outline.svg) no-repeat center
          center;

        display: block;
      }

      .slick-next {
        background: url(../images/round-right-arrow-outline.svg) no-repeat
          center center;

        left: calc(50% + 30px);
        display: block;
      }
    }
  }
}
.close-popover.referral-tootlip {
  @media all and (max-width: 400px) {
    width: 200px;
  }
}
