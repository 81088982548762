@keyframes blinkRed {
  from,
  0% {
    background-color: var(--color-red);
  }
  to,
  100% {
    background-color: transparent;
  }
}

@keyframes blinkGreen {
  from,
  0% {
    background-color: var(--color-green);
  }
  to,
  100% {
    background-color: transparent;
  }
}

@keyframes fadeInRed {
  from,
  0% {
    background-color: var(--color-red);
  }
  to,
  100% {
    background-color: rgba(255, 61, 0, 0.2);
  }
}

@keyframes fadeInGreen {
  from,
  0% {
    background-color: var(--color-green);
  }
  to,
  100% {
    background-color: rgba(43, 205, 118, 0.2);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(50%);
  }
}

@-webkit-keyframes flash {
  from,
  40%,
  80%,
  to {
    opacity: 1;
  }

  20%,
  60% {
    opacity: 0;
  }
}

@keyframes flash {
  from,
  40%,
  80%,
  to {
    opacity: 1;
  }

  20%,
  60% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@keyframes bounceInLeft {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-0.5px, 0, 0);
  }
}
