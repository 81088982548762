.press-coverage {
  &-container {
    background-color: var(--color-background-l0);

    padding: 0 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media screen and (max-width: 767px) {
      padding: 40px 0;
    }
  }
  &-header {
    display: flex;
    justify-content: center;
    height: 65px;
    align-items: center;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      height: 2px;
      width: 40px;
      background: var(--color-divider);
    }
    &-title {
      text-transform: uppercase;
      color: var(--color-text-l2);
      font-size: 24px;
      text-align: center;
      font-weight: 700;
    }
  }
  &-list-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    overflow: hidden;
    &:hover {
      .press-coverage-list {
        animation-play-state: paused;
      }
    }
  }
  &-list {
    display: flex;
    height: 100px;
    margin: 0 auto;
    transform-origin: center;
    animation: marquee 30s cubic-bezier(0.01, 0, 0.59, 0.78) alternate-reverse
      infinite;
    flex-shrink: 0;
    &-item {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-grow: 1;
      align-items: center;
      flex-shrink: 0;
      max-width: 250px;
      &-logo {
        width: 60%;
      }
    }
  }
}
