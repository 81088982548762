.responsive-popup {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &:not(.no-overflow-setting) {
    overflow: auto;
  }
  &-body {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    svg {
      margin: 8px auto;
    }
  }
  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    flex-shrink: 0;
    box-shadow: inset 0 1px 0 0 var(--color-shadow);
    .button-text {
      display: block;
      text-align: center;
      width: 100%;
    }
  }
  &-list {
    padding: 8px 24px 8px 24px;
    background: var(--color-background-warning);
    //margin: 8px -24px;
    list-style-position: outside;
    list-style-type: decimal;
    font-size: 12px;
    li {
      padding: 4px 0;
    }
  }
  &-table {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 12px 24px;
    .table {
      margin-bottom: 0;
      // Below snippet is added to make this table scrollable.
      tbody {
        overflow: auto;
        width: 100%;
        height: 100%;
        display: block;
        tr {
          position: relative;
          width: 100%;
          display: table;
          td {
            width: 50%;
          }
        }
      }
      thead {
        display: block;
        overflow: auto;
        width: 100%;
        tr {
          position: relative;
          width: 100%;
          display: table;
          th {
            width: 50%;
          }
        }
      }
      // *************//
    }
  }
}
