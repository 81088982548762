.pagination {
  margin: 2px auto;
  > li {
    > a,
    > span {
      padding: 0px;
    }
  }
  > .active {
    > a,
    > span {
      padding: 1px 8px;
      font-size: 12px;
      &,
      &:hover,
      &:focus {
        background-color: var(--color-background-l1);
        border-color: var(--color-divider);
        color: var(--color-text-l1);
      }
    }
  }
  > .disabled {
    > span {
      &,
      &:hover,
      &:focus {
        background-color: var(--color-background-l0);
        border-color: var(--color-divider);
        color: var(--color-text-l2);
      }
    }
  }
}
