.yi {
  &-wazirx-logo {
    position: absolute;
    left: 7%;
    top: 5%;
    transform: scale(1.3);
    @media screen and (max-width: 767px) {
      left: 5%;
      margin-left: 10%;
    }
  }
  &-sharer {
    margin-top: -1px;
    background: var(--color-background-l1);
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 50px 0;
    align-items: center;
    &-title {
      &-text {
        font-size: 16px;
        color: var(--color-text-l2);
      }
    }
    &-buttons {
      display: flex;
      margin: 8px -5px;
      width: 100%;
      max-width: 300px;
      &-item {
        display: flex;
        flex-basis: 25%;
        justify-content: center;
        align-items: center;
        height: 48px;
        border-radius: 4px;
        font-size: 18px;
        &.visible-xs-flex {
          display: none;
          @media screen and (max-width: 767px) {
            display: flex;
          }
        }
        &.hidden-xs-flex {
          display: flex;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        color: var(--color-white);
        margin: 0 5px;
        &.item-facebook {
          background: var(--color-vendors-facebook);
        }
        &.item-whatsapp {
          background: var(--color-vendors-whatsapp);
        }
        &.item-telegram {
          background: var(--color-vendors-telegram);
        }
        &.item-twitter {
          background: var(--color-vendors-twitter);
        }
      }
    }
  }
  &-0 {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;

    &-box {
      display: flex;
      height: 100%;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    &-section {
      display: flex;
      width: 50%;
      height: 100%;
      align-items: center;
      @media screen and (max-width: 767px) {
        width: 100%;
        &.first {
          height: 35%;
          align-items: flex-end;
        }
        &.second {
          height: 65%;
          justify-content: center;
        }
      }

      &.second {
        justify-content: center;
      }
    }
    &-img {
      height: 100%;
      width: 80%;
    }
    &-title {
      color: var(--color-text-l2);
      font-size: 100px;
      letter-spacing: 1px;
      font-weight: bold;
      line-height: 0.77em;
      @media screen and (max-width: 767px) {
        font-size: 60px;
      }
    }
    &-sub {
      color: var(--color-white);
      font-size: 61px;
      font-weight: bold;
      letter-spacing: 1px;
      margin-top: -25px;
      line-height: 1em;
      @media screen and (max-width: 767px) {
        font-size: 36px;
        margin-top: -15px;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      margin: 0 10%;
    }
  }
  &-scroll-button {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    button {
      all: unset;
    }
  }
  &-list {
    &-summary {
      display: flex;
      background-color: var(--color-background-l1);
      position: relative;
      flex-direction: column;
      margin-top: 25px;
      padding-bottom: 25px;
      &-row {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        &-item {
          background: var(--color-background-l0);
          display: flex;
          padding: 25px;
          box-shadow: 0 15px 40px 6px var(--color-shadow);
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 25px;
          width: 30%;
          height: 250px;
          @media screen and (max-width: 767px) {
            width: auto;
          }
          justify-content: center;
          &-title {
            font-size: 40px;
            font-weight: bold;
            margin: 20px 0;
            color: var(--color-text-l1);
          }
          &-desc {
            font-size: 16px;
            color: var(--color-text-l2);
          }
        }
      }
    }

    &-separator {
      display: flex;
      //height: 200px;
      background-color: var(--color-background-l1);
      position: relative;
      justify-content: center;
      align-items: center;
      padding: 50px 0;
      margin-bottom: -50px;
      margin-top: -1px;
      @media screen and (max-width: 767px) {
        padding: 25px 0;
        margin-bottom: -50px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 90%;
        border: 2px dashed var(--color-divider);
      }
      &-text {
        font-size: 80px;
        font-weight: bold;
        color: var(--color-text-l2);
        background: var(--color-background-l1);
        display: block;
        padding: 0 2%;
        z-index: 1;
        @media screen and (max-width: 767px) {
          font-size: 60px;
        }
      }
    }

    &-item {
      background-color: var(--color-background-l1);
      height: 480px;
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: -1px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-bottom: -1px;
        height: auto;
        padding: 50px 0;
      }
      &.reverse {
        flex-direction: row-reverse;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
      }
      &.transparent {
        background: transparent;
        height: 100vh;
        padding: 0;
        @media screen and (max-width: 767px) {
          margin-bottom: 2px;
        }
      }

      &-section {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 767px) {
          width: 100%;
          height: auto;
          padding: 25px 0;
        }
      }

      &-ig {
        padding: 40px;
        border-radius: 40px;
        background: var(--color-background-l0);
        box-shadow: 0 15px 40px 6px var(--color-shadow);
      }

      &-box {
        margin: 0 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-header {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &-title {
          font-size: 100px;
          color: var(--color-text-l1);
          opacity: 0.05;
          font-weight: bold;
          @media screen and (max-width: 767px) {
            font-size: 66px;
          }
        }
        &-sub {
          font-size: 24px;
          font-weight: bold;
          color: var(--color-text-l2);
          margin: -40px 0 40px;
          text-align: center;
          @media screen and (max-width: 767px) {
            font-size: 18px;
            margin: -25px 0 25px;
          }
        }
        &-description {
          &-text {
            color: var(--color-text-l2);
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.44;
            text-align: center;
            display: inline-block;
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
