.react-tagsinput {
  background-color: var(--color-background-input);
  border: solid 1px var(--color-divider);
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  width: 100%;
  border-radius: 4px;
  &--focused {
    border-color: var(--color-primary);
  }
  &-tag {
    background-color: var(--color-primary);
    border-radius: 2px;
    border: 1px solid var(--color-primary);
    color: var(--color-white);
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 10px;

    a {
      &:before {
        content: ' ×';
      }
    }
  }

  &--error {
    border-color: var(--color-red);
  }

  &-remove {
    cursor: pointer;
    font-weight: bold;
    color: var(--color-white);
  }
  &-input {
    background: transparent;

    color: var(--color-text-l1);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    line-height: 1.25em;
    width: 80px;
    input {
      border: 0;
    }
  }
}
