.coupons {
  color: var(--color-text-l1);
  display: grid;
  &-panel {
    display: grid;
    background-color: var(--color-background-l0);
    border-radius: 4px;
    padding: 16px 18px;
    box-shadow: 0 2px 4px 0 var(--color-shadow);
    row-gap: 8px;
    margin-bottom: 10px;
  }
  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    row-gap: 4px;
    column-gap: 8px;
    @media screen and (max-width: 767px) {
      grid-template-columns: auto;
      grid-template-rows: auto;
      row-gap: 8px;
    }
    &-title {
      grid-column: 1;
      grid-row: 1;
      font-size: 20px;
      line-height: 1.2em;
      font-weight: bold;
      color: var(--color-text-l1);
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    &-description {
      grid-column: 1;
      grid-row: 2;
      font-size: 12px;
      line-height: 1.5em;
      color: var(--color-text-l2);
    }

    &-illustration {
      grid-column: 2;
      grid-row: 1 / 3;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  &-label {
    font-weight: bold;
    color: var(--color-text-l2);
  }
  &-form {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 16px;
    &-button {
      width: 140px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: auto;
      row-gap: 8px;
    }
  }

  &-redeemed {
    &-title {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.65px;
      line-height: 1.2em;
      @media screen and (max-width: 767px) {
        padding: 0 18px;
      }
    }
    &-panel {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding-top: 12px;
      @media screen and (min-width: 768px) {
        background-color: var(--color-background-l0);
        border-radius: 4px;
        padding: 16px 18px;
        box-shadow: 0 2px 4px 0 var(--color-shadow);
      }
    }
    &-wrapper {
      display: grid;
      row-gap: 8px;
    }

    &-loader {
      display: grid;
      justify-content: center;
      align-items: center;
      height: 50px;
    }

    &-card {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      row-gap: 8px;
      color: var(--color-text-l1);
      background-color: var(--color-background-l0);
      border-radius: 4px;
      padding: 16px 18px;

      @media screen and (max-width: 767px) {
        box-shadow: 0 2px 4px 0 var(--color-shadow);
      }
      @media screen and (min-width: 768px) {
        border: solid 1px var(--color-divider);
      }

      &-wrapper {
        grid-column: 1 / 3;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        column-gap: 8px;
      }
      &-reward {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        align-items: baseline;
        &-amount {
          font-size: 32px;
          font-weight: bold;
          line-height: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 767px) {
            font-size: 24px;
          }
        }
        &-currency {
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
          margin-left: 4px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
      &-code {
        display: grid;
        justify-content: end;
        &-text {
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.65px;
          padding: 8px;
          border: 1px dashed var(--color-divider);
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
      &-valid {
        display: grid;
        grid-template-columns: auto auto;
        align-items: baseline;
        @media screen and (max-width: 767px) {
          align-items: center;
        }
        justify-content: end;
        &-label {
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.5px;
          font-weight: bold;
          margin-right: 5px;
          @media screen and (max-width: 767px) {
            font-size: 10px;
          }
        }
        &-date {
          white-space: nowrap;
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
      &-status {
        display: grid;
        justify-content: start;
        align-items: start;
        &-text {
          font-size: 10px;
          font-weight: bold;
          padding: 4px 8px;
          border-radius: 4px;
          text-transform: uppercase;
          &.rewarded {
            color: var(--color-green);
            background-color: var(--color-background-success);
          }
          &.pending {
            color: var(--color-warning);
            background-color: var(--color-background-warning);
          }
          &.expired {
            color: var(--color-red);
            background-color: var(--color-background-danger);
          }
        }
      }
      &-description {
        grid-column: 1 / 3;
        text-align: left;
        padding: 8px 0 0;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      &-details {
        grid-column: 1 / 3;
        display: grid;
        justify-content: end;
      }
    }
  }
}
