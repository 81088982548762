.toggle-group {
  position: absolute;
  right: 0;
  top: 0;
  &.full-width {
    display: flex;
    width: 100%;
    .btn {
      flex-grow: 1;
    }
  }
  .btn.btn-default {
    position: relative;
    border: 0;
    border-radius: 0;
    font-size: 11px;
    font-weight: bold;
    padding: 8px 12px 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: var(--color-text-l2);
    text-transform: uppercase;
    background: var(--color-background-l1);
    position: relative;

    &.preventTextOverlap {
      // Introduced because of this issue :  https://wazirx.atlassian.net/browse/WAZ-6600
      background: var(--color-background-l1);
      box-shadow: inset 0px -1px 0px 0px var(--color-shadow);
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: var(--color-background-l0);
      border: none;
    }
    &:last-child {
      box-shadow: 0 0px 2px 0 var(--color-shadow);
      &.active {
        &:before {
          border-right: 0;
        }
        &:after {
          border-top-right-radius: 4px;
        }
      }
    }
    &.active {
      color: var(--color-text-l1);
      background: var(--color-background-l0);
      border-bottom: none;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 4px;
        background-color: var(--color-green);
        left: 0;
        right: 0;
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: transparent;
        bottom: -1px;
        left: 0;
        right: 0;
        border-left: 1px solid var(--color-divider);
        border-bottom: 1px solid var(--color-background-l0);
        border-right: 1px solid var(--color-divider);
        border-top: 0;
      }
    }
  }

  .btn.btn-primary {
    position: relative;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 2px 7px;
    text-transform: uppercase;
    background: var(--color-background-l1);
    color: var(--color-text-l3);
    &:not(:first-child) {
      border-left: 1px solid var(--color-divider);
    }
    border-bottom: 1px solid var(--color-divider);
    &:hover,
    &:focus,
    &:active,
    &.active {
      box-shadow: none;
      color: var(--color-text-l1);
    }
    &.active {
      color: var(--color-text-l1);
      background: var(--color-background-l0);
      border-bottom: none;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 4px;
        background-color: var(--color-green);
        left: 0;
        right: 0;
      }
    }
  }
}
