.matched-box {
  margin: 0 auto 36px;
  display: flex;
  flex-direction: column;
  max-width: 720px;
  &-nav {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    &-link {
      font-size: 14px;
      color: var(--color-text-l2);
      cursor: pointer;
      &:hover {
        color: var(--color-text-l1);
      }
    }
  }
  &-confirmation-popup {
    &-title {
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 1.2em;
      font-weight: bold;
    }
    &-text {
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 1.2em;
      font-weight: 500;
    }
    &-subtext {
      margin-top: 12px;
      font-size: 12px;
      font-weight: bold;
      color: var(--color-text-l1);
      span[role='img'] {
        padding-right: 8px;
      }
    }
    &-options {
      padding: 16px 0 0;
      &-title {
        font-weight: 700;
      }
      &-list {
        display: flex;
        padding-top: 8px;
        &-item {
          flex: 1 1 auto;
          &-label {
            width: 100%;
          }
        }
      }
    }
  }
  &-order {
    display: flex;
    justify-content: center;
    &-label {
      font-size: 12px;
      color: var(--color-text-l2);
    }
  }
  &-status {
    background-color: var(--color-background-l0);
    box-shadow: 1px 1px 2px var(--color-shadow);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: var(--color-text-l2);
    &-cancel {
      top: 0;
      right: 0;
      color: var(--color-red);
      padding: 0 4px;
      &:hover {
        background-color: var(--color-red);
        .matched-box-status-cancel-text {
          color: var(--color-white);
        }
      }
      &-text {
        font-size: 12px;
        color: var(--color-red);
      }
    }
    &-title {
      padding: 25px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 767px) {
        &.sell {
          padding: 40px 25px 15px;
        }
        flex-direction: column;
        align-items: center;
      }
      &-text {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.5px;
        padding: 0 4px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }

      &-link {
        color: var(--color-primary);
        &:hover {
          opacity: 0.95;
          color: var(--color-primary);
          border-bottom: 1px solid var(--color-primary);
        }
        font-weight: 700;
        font-size: 14px;
        border-bottom: 1px dashed var(--color-primary);
        cursor: pointer;
        text-transform: uppercase;
      }
    }
    &-note {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      border: 1px solid var();
      margin: 12px auto 0;
      border-radius: 4px;
      background: var(--color-background-warning);
      color: var(--color-text-l1);
      max-width: 360px;
      &-text {
        font-weight: bold;
      }
      &-subtext {
        text-align: right;
        padding-left: 8px;
      }
      &-item {
        &:not(:first-child) {
          border-top: 1px solid var(--color-background-warning);
        }
        padding: 8px;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
      }
    }
    &-desc {
      padding: 0 16px 10px 16px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
      &-text {
        font-size: 14px;
        font-weight: 500;
      }
      &-timestamp {
        color: var(--color-text-l2);
        font-size: 12px;
        @media screen and (max-width: 767px) {
          padding: 4px 0 0;
        }
      }
    }
    &-progress {
      position: relative;
      &-remaining {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 8px;
        &-text {
          font-size: 10px;
          color: var(--color-text-l2);
        }
      }
      &-bar {
        &.progress {
          margin-bottom: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          height: 13px;
          background-color: var(--color-background-l1);
          @media screen and (max-width: 767px) {
            height: 10px;
          }
          .progress-bar {
            background-color: var(--color-green);
          }
        }
      }
    }
  }
  &-trades {
    padding: 8px 0 0;
    display: flex;
    flex-direction: column;
    &-item {
      background-color: var(--color-background-l0);
      box-shadow: 1px 1px 2px var(--color-shadow);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      margin: 8px 0;
      overflow: hidden;
      &-expired {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
      }
      &.preselectedTrade {
        border: 1px solid var(--color-primary);
      }
      &-tooltip {
        &-text {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 1.67;
        }
        &-icon {
          margin-bottom: -2px;
          margin-left: 5px;
        }
      }
      &-title {
        margin-top: 5px;
        padding: 16px 25px;
        display: flex;
        align-items: center;
        flex-direction: column;
        &-text {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0.5px;
        }
        &-timestamp {
          padding: 4px 0 0;
          color: var(--color-text-l2);
          font-size: 12px;
        }
      }
      &-pending {
        display: flex;
        justify-content: center;
        padding: 16px;
        align-items: center;
        &-text {
          padding: 0 8px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            padding: 0 16px;
          }
        }
      }
      &-waiting {
        background-color: var(--color-green);
        padding: 4px 16px;
        display: flex;
        justify-content: center;
        &-text {
          color: var(--color-white);
          font-size: 12px;
          font-weight: 500;
        }
      }
      &-completed {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 1px 1px 2px var(--color-shadow);
        border-radius: 4px;
        background: var(--color-background-l0);
        padding: 8px 0;
        border: 1px solid var(--color-green);
        margin: 8px 0;
        &-logo {
          display: flex;
          padding: 8px 0;
        }
        &-title {
          display: flex;
          padding: 8px 0;
          align-items: center;
          flex-direction: column;
          &-text {
            font-size: 16px;
            font-weight: 700;
          }
          &-timestamp {
            padding: 4px 0 0;
            color: var(--color-text-l2);
            font-size: 12px;
          }
        }
        &-description {
          display: flex;
          padding: 8px;

          &-text {
            font-size: 14px;
            font-weight: 700;
            text-align: center;
          }
        }
        &.preselectedTrade {
          border: 1px solid var(--color-primary);
        }
      }
      &-payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-logo {
          display: flex;
          padding: 8px 0;
        }
        &-title {
          display: flex;
          padding: 8px 0;
          &-text {
            font-size: 16px;
            font-weight: 700;
          }
        }

        &-description {
          display: flex;
          &-text {
            font-size: 12px;
            font-weight: 600;
            text-align: center;
          }
        }
        &-support {
          display: flex;
          padding: 16px 0;
          &-link {
            font-size: 12px;
            color: var(--color-text-l2);
            text-decoration: underline;
            cursor: pointer;
            &:hover {
              color: var(--color-text-l1);
            }
          }
        }
        &-timer {
          padding: 4px 0;
          &-progress {
            width: 200px;
            &-bar {
              &.progress {
                margin-bottom: 0;
                height: 13px;
                .progress-bar {
                  background-color: var(--color-red);
                }
              }
            }
          }
        }
      }
      &-timer {
        background-color: var(--color-background-l1);
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-box {
          max-width: 300px;
          display: flex;
          margin-bottom: 16px;
          &-left {
            opacity: 0.8;
            display: flex;
          }
          &-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 0 0 16px;
            .cta {
              font-size: 12px;
              font-weight: 700;
              color: var(--color-text-l2);
              text-transform: uppercase;
            }
            .timer {
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
        &-progress {
          width: 200px;
          &-bar {
            &.progress {
              margin-bottom: 0;
              height: 13px;
              .progress-bar {
                background-color: var(--color-red);
              }
            }
          }
        }
      }
      &-account {
        background-color: var(--color-background-l0);
        padding: 16px 16px 8px;
        @media screen and (min-width: 768px) {
          padding: 16px;
          .row {
            display: flex;
            align-items: center;
          }
        }
        &-show-strip {
          padding: 8px 0;
          display: flex;
          justify-content: center;
          background: var(--color-text-l2);
          color: var(--color-white);
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          text-transform: uppercase;
          cursor: pointer;
          &-text {
            font-size: 12px;
          }
        }
        &-paid {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-title {
            font-size: 14px;
            padding-bottom: 8px;
          }
        }
        &-pay-exactly {
          display: flex;
          flex-direction: column;
          border-right: 1px solid var(--color-divider);
          padding: 16px 5px;
          justify-content: center;
          @media screen and (max-width: 767px) {
            border-right: 0;
            padding: 8px 0;
            align-items: center;
            padding-bottom: 24px;
          }
          &-title {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            color: var(--color-text-l2);
          }
          &-amount {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0.5px;
          }
          &-subtitle {
            font-size: 12px;
            line-height: 1.33em;
            letter-spacing: 0.5px;
          }
        }
        &-types {
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 768px) {
            padding-left: 15px;
          }
          &-label {
            text-align: center;
            color: var(--color-text-l2);
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
          }
          &-list {
            display: flex;
            justify-content: space-evenly;
            padding: 4px 0;
            flex-wrap: wrap;
            &-item {
              display: flex;
              flex: 0 0 30%;
              height: 50px;
              justify-content: center;
              align-items: center;
              border: 1px solid var(--color-primary);
              border-radius: 4px;
              margin: 4px 0;
              @media screen and (max-width: 767px) {
                height: 50px;
              }
              @media screen and (max-width: 400px) {
                height: 30px;
              }
              img {
                width: 90%;
              }
              &:hover {
                border: 1px solid var(--color-primary);
              }
            }
          }
        }
        &-details {
          display: flex;
          flex-wrap: wrap;
          padding: 8px 12px 4px;

          @media screen and (max-width: 767px) {
            padding: 8px 12px;
            border-bottom: 1px solid var(--color-divider);
          }
          &-item {
            padding: 8px 4px;
            display: flex;
            width: 100%;
            &-highlight {
              margin: 0 -8px;
              box-shadow: 0px 1px 1px var(--color-shadow),
                0px 1px 2px var(--color-shadow);
              border-radius: 4px;
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              flex-shrink: 0;
              max-width: calc(100% + 16px);
              &-container {
                display: flex;
                position: relative;
                justify-content: space-between;
                flex-grow: 1;
                border: 2px solid var(--color-primary);
                border-radius: 4px 4px 0 0;
                padding: 4px 6px;
              }
              &-message {
                background: var(--color-primary);
                display: flex;
                color: var(--color-white);
                border-radius: 0 0 4px 4px;
                &-text {
                  padding: 4px 8px;
                  font-size: 10px;
                  font-weight: 600;
                  line-height: 1.67;
                  letter-spacing: 0.4px;
                }
              }
            }
            &-title {
              font-size: 12px;
              font-weight: 700;
              color: var(--color-text-l2);
              text-transform: uppercase;
              display: flex;
              align-items: center;
              flex-grow: 1;
            }
            &-content {
              display: flex;
              justify-content: space-between;

              &-text {
                font-size: 14px;
                font-weight: 500;
                text-align: right;
                &.copying {
                  color: var(--color-primary);
                }
                &-primary {
                  color: var(--color-primary);
                }
              }
              &-copy {
                opacity: 0.6;
                padding: 0 0 0 8px;

                &-primary {
                  opacity: 1;
                  padding: 0 0 0 8px;
                  color: var(--color-primary);
                }

                &:hover {
                  opacity: 1;
                }
              }
            }
          }

          &.dark {
            background: var(--color-gray);
            .matched-box-trades-item-account-details-item {
              &-title,
              &-content {
                color: var(--color-white);
              }
            }
          }
          &.full-width {
            width: 100%;
            justify-content: space-evenly;
            .matched-box-trades-item-account-details-item {
              width: auto;
              display: flex;
              flex-direction: column;
              @media screen and (max-width: 767px) {
                justify-content: space-between;
                flex-direction: row;
                width: 100%;
              }
              &-title {
                text-align: center;
              }
            }
          }
        }
      }
      &-confirmation {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        border-top: 1px solid var(--color-divider);
        &-header {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          padding: 16px 0;
          &-back {
            padding: 0px 12px 8px;
            font-size: 14px;
            cursor: pointer;
            i {
              font-size: 14px;
            }
          }
          &-title {
            padding: 0 16px;
            font-size: 18px;
            font-weight: bold;
            color: var(--color-text-l1);
          }
          &-subtitle {
            font-size: 12px;
            color: var(--color-text-l1);
            padding: 0 16px;
            letter-spacing: 0.5px;
          }
        }
        &-check {
          display: flex;
          justify-content: center;
          padding: 8px 16px;
          border-top: 1px solid var(--color-divider);
          &.seller-check {
            justify-content: flex-start;
            padding: 16px 0 0;
            border: none;
          }
          .checkbox {
            margin-top: 0;
            margin-bottom: 0;
          }
          &-label {
            font-size: 12px;
            font-weight: 600 !important;
          }
        }
        &-button{
          padding: 8px 0px 16px;
          display: flex;
          justify-content: center;
          width: 100%;
          &-text{
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
          }
        }
        &-subtitle {
          color: var(--color-text-l2);
          font-size: 11px;
          text-align: center;
        }
        &-title {
          display: flex;
          justify-content: center;
          padding: 10px 16px 10px;
          &-text{
            font-size: 14px;
            font-weight: 500;
            text-align: center;
          }
        }
        &-amount {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: var(--color-background-l1);
          padding: 16px;
          &-title {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            color: var(--color-text-l2);
          }
          &-content {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0.5px;
            position: relative;
            &-text {
              &.copying {
                color: var(--color-primary);
              }
            }
            &-copy {
              opacity: 0.6;
              padding: 8px 0 0 10px;
              font-size: 16px;
              position: absolute;
            }
          }
          &-subtitle {
            font-size: 12px;
            line-height: 1.33em;
            letter-spacing: 0.5px;
          }
        }
        &-description {
          display: flex;
          justify-content: center;
          padding: 4px 16px 8px;
          &-text {
            text-align: center;
          }
        }
        &-text {
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          margin-top: 18px;
        }
      }
    }
  }
}
