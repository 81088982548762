.two-fa {
  &-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &:not(.no-overflow-setting) {
      overflow: auto;
    }
    &-body {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex-direction: column;
      flex-grow: 1;
      padding: 24px;
      flex-shrink: 0;
      svg {
        margin: 8px auto;
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 24px 24px;
      flex-shrink: 0;
      flex-direction: column;
      box-shadow: inset 0 -1px 0 0 var(--color-shadow);
    }
    &-title {
      font-weight: 700;
      text-align: center;
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      &.copying {
        color: var(--color-primary);
      }
    }
    &-list {
      padding: 8px 24px 8px 40px;
      background: var(--color-background-warning);
      margin: 8px -24px;
      list-style-position: outside;
      list-style-type: decimal;
      li {
        padding: 4px 0;
      }
    }
    &-link {
      cursor: pointer;
      line-height: 1.67;
      color: var(--color-primary);
      text-decoration: none;
      letter-spacing: 0.5px;
      &:hover {
        text-decoration: underline;
      }
    }
    &-input-wrapper {
      width: 156px;
      margin: 24px auto 0;
    }
    &-description {
      text-align: center;
      line-height: 1.2em;
      letter-spacing: 0.5px;
      &.dashed-border-box {
        border: 1px dashed var(--color-divider);
        padding: 8px;
        border-radius: 2px;
      }
    }
    &-submit-text {
      text-align: center;
      font-weight: bold;
      width: 100%;
    }
    &-check {
      display: flex;
      justify-content: center;
      margin-top: 8px;
      margin-bottom: 16px;
      flex-shrink: 0;
    }
    &-separator {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      position: relative;
      flex-shrink: 0;
      &:before {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        background: var(--color-divider);
        z-index: 1;
      }

      &:after {
        content: 'OR';
        display: block;
        background: var(--color-background-l0);
        z-index: 2;
        padding: 0 12px;
        font-size: 12px;
        color: var(--color-text-l3);
      }
    }
    &-box {
      display: flex;
      justify-content: center;
      padding: 8px;
      align-items: center;
      flex-shrink: 0;
      &.qr-box {
        position: relative;
        border: 2px solid var(--color-divider);
        margin: 0 auto;
        background: var(--color-white);
        &:before {
          content: '';
          height: 80%;
          background: transparent;
          position: absolute;
          z-index: 0;
          left: -2px;
          right: -2px;
          top: 10%;
          border-left: 2px solid var(--color-white);
          border-right: 2px solid var(--color-white);
        }
        &:after {
          content: '';
          width: 80%;
          background: transparent;
          position: absolute;
          z-index: 0;
          top: -2px;
          bottom: -2px;
          left: 10%;
          border-top: 2px solid var(--color-white);
          border-bottom: 2px solid var(--color-white);
        }
        & > * {
          z-index: 1;
        }
      }
      &.code-box {
        border: 1px dashed var(--color-divider);
        border-radius: 4px;
        margin-top: 8px;
        background: var(--color-background-l0);
        &.copying {
          background: var(--color-background-l1);
        }
        &:hover {
          border: 1px dashed var(--color-primary);
          box-shadow: 0 1px 1px var(--color-shadow);
        }
      }
    }
    &-section {
      display: flex;
      flex-direction: column;
      margin: 8px 0;
      flex-shrink: 0;
    }
  }
}
