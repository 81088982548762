.list-sort {
  display: table;
  width: 100%;
  .list-sort-item {
    cursor: pointer;
    font-size: 10px;
    color: var(--color-text-l2);
    font-weight: 600;
    display: table-cell;
    padding: 2px 8px 2px 16px;
    &:last-of-type {
      text-align: right;
    }
    .marker {
      opacity: 0;
    }
    &.active {
      color: var(--color-green);
      position: relative;
      .marker {
        opacity: 1;
      }
    }
  }
}
