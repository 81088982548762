.binance {
  &-landing {
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    position: relative;
    background-color: var(--color-background-l0);
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
    &-lane {
      display: grid;
      float: left;
      &.fixed {
        align-items: center;
        position: sticky;
        top: 0;
        width: 50%;
        @media screen and (max-width: 1199px) {
          width: 45%;
        }
        @media screen and (max-width: 767px) {
          position: relative;
          width: 100%;
        }
      }
      &.scrollable {
        background-image: linear-gradient(
          9deg,
          var(--color-background-hero) 15%,
          var(--color-background-l0) 97%
        );
        position: relative;
        width: 50%;
        @media screen and (max-width: 1199px) {
          width: 55%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    &-login {
      display: grid;
      grid-auto-rows: minmax(min-content, max-content);
      margin: 24px 10%;
      @media screen and (max-width: 767px) {
        margin: 24px 5%;
        justify-items: center;
      }
      > .heading {
        font-weight: bold;
        letter-spacing: -0.5px;
        margin: 30px 0 0;
        @media screen and (max-width: 767px) {
          text-align: center;
          margin-top: 18px 0 0;
          font-size: 24px;
        }
      }
      > .logo {
        height: 173px;
      }
      > .sub-heading {
        margin-top: 15px;
        @media screen and (max-width: 767px) {
          padding: 0 16px;
          text-align: center;
        }
      }
      .button-text {
        font-size: 16px;
        font-weight: bold;
        margin-left: 16px;
        text-transform: uppercase;
      }
    }
    &-info {
      display: grid;
      grid-template-rows: 50px auto;
      row-gap: 40px;
      margin: auto 10%;
      padding: 24px 0;
      @media screen and (max-width: 767px) {
        padding: 0;
        margin: 24px 8%;
        row-gap: 20px;
      }
      .heading {
        display: flex;
        align-items: center;
        &-text {
          margin: 0;
          font-weight: bold;
        }
      }
      .steps {
        display: grid;
        grid-auto-rows: minmax(min-content, max-content);
        row-gap: 50px;
        @media screen and (max-width: 767px) {
          row-gap: 35px;
        }
        &-item {
          display: grid;
          grid-template-columns: 100px auto;
          grid-auto-rows: minmax(min-content, max-content);
          column-gap: 40px;
          @media screen and (max-width: 767px) {
            grid-template-columns: 65px auto;
            column-gap: 20px;
          }
          &-icon {
          }
          &-info {
            display: grid;
            grid-auto-rows: minmax(min-content, max-content);
            row-gap: 10px;
            padding: 10px 0;
            @media screen and (max-width: 767px) {
              row-gap: 5px;
              padding: 5px 0;
            }
            &-title {
              font-size: 16px;
              font-weight: bold;
              line-height: 1.5em;
            }
            &-description {
              font-size: 14px;
              color: var(--color-text-l2);
              line-height: 1.57;
            }
          }
        }
      }
    }
  }
}
