.p2p {
  width: 100%;
  padding: 16px 0 16px !important;
  @media screen and (max-width: 767px) {
    height: 100%;
  }
  &.loading {
    height: 100%;
  }
  &-loader {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-help {
    display: flex;
    flex-direction: column;
    background-color: var(--color-background-default);
    @media screen and (max-width: 767px) {
      overflow-y: auto;
      flex-grow: 1;
    }
    &-body {
      padding: 25px;
      flex-grow: 1;
      &-loader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        &-text {
          text-align: center;
          font-size: 12px;
          color: var(--color-text-l1);
          font-weight: 400;
          text-transform: none;
          padding: 10px 0;
        }
      }
    }
    &-articles {
      &-item {
        list-style-type: none;
        background-color: var(--color-background-l0);
        border-bottom: 1px solid var(--color-divider);
        font-size: 16px;
        display: flex;
        box-shadow: 2px 2px 4px 0 var(--color-shadow);
        margin: 10px 0;
        border-radius: 4px;
        a {
          color: var(--color-text-l1);
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 1.2em;
          padding: 15px;
          flex-grow: 1;
        }
        .mdi {
          color: var(--color-primary);
        }
        &:hover {
          background: var(--color-primary);
          a,
          .mdi {
            color: var(--color-white);
          }
        }
      }
    }
  }
  &-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    border-top: 1px solid var(--color-divider);
    padding-top: 12px;
    @media screen and (max-width: 767px) {
      padding-top: 12px 0;
    }
    &-heading {
      &-title {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding-right: 10px;
      }
      &-subtitle {
        font-size: 12px;
        font-weight: 500;
      }
    }
    &-image {
      width: 100%;
      margin: 16px 0 0px auto;
      @media screen and (max-width: 767px) {
        padding: 8px 0 36px;
      }
    }
  }
  &-tables {
    display: flex;
    flex-direction: column;
    height: 720px;
  }
}
