@import 'palette';
:root {
  @each $colorname, $palette in $palettes {
    @each $key, $value in $palette {
      $keyname: '--palette-' + $colorname + '-' + $key;
      #{$keyname}: #{$value};
    }
  }
}

:root.theme-dark {
  &:not(.disableThemeOverride) {
    color-scheme: dark; // window color scheme, for scrollbars and native elements

    --color-green: var(--palette-green-50);
    --color-red: var(--palette-red-50);

    --color-background-danger-icon-bg: var(--palette-red-100);
    --color-background-danger-icon-color: var(--palette-red-70);

    //Important component colors
    --color-important-component-bg: var(--palette-yellow-100);
    --color-important-component-border: var(--palette-yellow-90);
    --color-important-component-text: var(--palette-yellow-70);

    //for text, levels work as prioritys. Lower means darker.
    --color-text-l0: var(--palette-gray-10);
    --color-text-l1: var(--palette-gray-30);
    --color-text-l2: var(--palette-gray-50);
    --color-text-l3: var(--palette-gray-60);
    --color-text-white: var(--palette-white-main);
    --color-text-primary: var(--palette-white-main);

    --color-text-link: var(--palette-blue-50);
    --color-text-link-hover: var(--palette-blue-40);

    --color-divider: var(--palette-white-alpha10);
    --color-grid: var(--palette-white-alpha10);

    // levels here work like z-index / elevation of card
    --color-background-default: var(--palette-gray-100); //base
    --color-background-l0: var(--palette-gray-90); //card
    --color-background-l1: var(--palette-gray-95); //above card
    --color-background-l2: var(--palette-gray-100);
    --color-background-activated: var(--palette-gray-80);
    --color-background-selected: var(--palette-gray-70);
    --color-background-hover: var(--palette-blue-100);
    --color-background-input: var(--palette-gray-80);
    --color-background-inputotp: var(--palette-gray-80);
    --color-background-modal: var(--palette-gray-alpha90);
    --color-background-hero: var(--palette-gray-90);
    --color-background-toggle: var(--palette-blue-80);
    --color-background-info: var(--palette-blue-100);

    --color-background-stepper: var(--palette-gray-80);
    --color-scrollbar-background: var(--palette-gray-80);
    --color-scrollbar-thumb: var(--palette-gray-50);

    //overrides
    --color-header-border: var(--palette-black-alpha20);
    --color-header-signup-btn: var(--palette-yellow-main);
    --color-header-signup-btn-hover: var(--palette-yellow-70);

    --color-table-row-red-overlay: var(--palette-red-100);
    --color-table-row-green-overlay: var(--palette-green-100);

    --color-outer-header-background: var(
      --palette-gray-80
    ); //for the landing page navbar - white color
    --color-outer-header-text: var(--palette-gray-50);

    --color-inner-header-text: var(
      --palette-white-main
    ); //the default text color of the header in the main products
    --color-inner-header-icon: var(--palette-white-alpha70);
    --color-inner-header-background: var(--palette-gray-80);
    --color-inner-header-btn-opened: var(--palette-white-alpha10);
    --color-inner-header-btn-selected: var(--palette-gray-100);
    --color-inner-header-btn-hover: var(--palette-white-alpha20);
    --color-inner-header-highlight-accent: var(--palette-yellow-30);
    --color-inner-header-popover: var(--palette-gray-80);

    --color-footer-background: var(--palette-gray-95);
    --color-table-row-hover: var(--palette-white-alpha10);
    --color-table-row-odd: var(--palette-gray-90);
    --color-table-row-even: var(--palette-gray-95);

    --color-tradingView-background: var(--palette-gray-90);

    --color-landing-hero-background: var(--palette-gray-90);

    --color-moneycontrol-primary: var(--palette-white-main);
    --color-moneycontrol-header-background: var(--palette-gray-80);
    --color-moneycontrol-lightblue: var(--palette-gray-80);

    //disabled input
    --color-disabled-input-bg: var(--palette-gray-80);
    --color-disabled-input-border: var(--palette-gray-80);
    --color-background-prev-tab: var(--palette-gray-70);
    --color-background-curr-tab: var(--palette-blue-50);
    --color-background-next-tab: var(--palette-gray-80);

    // some text colors
    --color-warning-text: var(--palette-yellow-60);

    // alert
    --color-alert-success-background: var(--palette-green-100);
    --color-alert-success-border: var(--palette-green-90);
    --color-alert-success-text: var(--palette-green-40);
    --color-alert-primary-background: var(--palette-blue-100);
    --color-alert-primary-border: var(--palette-blue-90);
    --color-alert-primary-text: var(--palette-blue-40);
    --color-alert-warning-background: var(--palette-yellow-100);
    --color-alert-warning-border: var(--palette-yellow-90);
    --color-alert-warning-text: var(--palette-yellow-40);
  }
}
