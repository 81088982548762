.table {
  &__thead {
    &__th {
      &.sortable {
        cursor: pointer;
      }
      .mdi.marker {
        opacity: 0;
        display: none;
      }
      &.active {
        color: var(--color-primary);
        background-color: var(--color-background-l1) !important;
        .mdi.marker {
          opacity: 1;
          display: inline;
          margin-left: 3px;
          margin-right: -3px;
        }
      }
      &__wrapper {
        white-space: nowrap;
      }
    }
  }
}
