//
// Checkboxes
// --------------------------------------------------

$font-family-icon: 'Material Design Icons' !default;
$fa-var-check: '\F12C' !default;
$check-icon: $fa-var-check !default;

@mixin checkbox-variant($parent, $color) {
  #{$parent} input[type='checkbox']:checked + label,
  #{$parent} input[type='radio']:checked + label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after {
      color: --color-white;
    }
  }
}

@mixin checkbox-variant-indeterminate($parent, $color) {
  #{$parent} input[type='checkbox']:indeterminate + label,
  #{$parent} input[type='radio']:indeterminate + label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after {
      background-color: --color-white;
    }
  }
}

.checkbox {
  padding-left: 20px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid $input-border;
      border-radius: 3px;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
      outline: none !important;
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: $input-color;
      line-height: 1.4;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus + label::before {
      @include tab-focus();
    }

    &:checked + label::after {
      font-family: $font-family-icon;
      content: $check-icon;
    }

    &:indeterminate + label::after {
      display: block;
      content: '';
      width: 10px;
      height: 3px;
      background-color: --color-gray;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled + label {
      opacity: 0.65;

      &::before {
        background-color: $input-bg-disabled;
        cursor: not-allowed;
      }
    }
  }

  &.checkbox-circle label::before {
    border-radius: 50%;
  }

  &.checkbox-inline {
    margin-top: 0;
  }
}

@include checkbox-variant('.checkbox-primary', $brand-primary);
@include checkbox-variant('.checkbox-danger', $brand-danger);
@include checkbox-variant('.checkbox-info', $brand-info);
@include checkbox-variant('.checkbox-warning', $brand-warning);
@include checkbox-variant('.checkbox-success', $brand-success);

@include checkbox-variant-indeterminate('.checkbox-primary', $brand-primary);
@include checkbox-variant-indeterminate('.checkbox-danger', $brand-danger);
@include checkbox-variant-indeterminate('.checkbox-info', $brand-info);
@include checkbox-variant-indeterminate('.checkbox-warning', $brand-warning);
@include checkbox-variant-indeterminate('.checkbox-success', $brand-success);

//
// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $color) {
  #{$parent} input[type='radio'] {
    + label {
      &::after {
        background-color: $color;
      }
    }
    &:checked + label {
      &::before {
        border-color: $color;
      }
      &::after {
        background-color: $color;
      }
    }
  }
}

.radio {
  padding-left: 20px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid $input-border;
      border-radius: 50%;
      background-color: $input-bg;
      outline: none !important;
      @include transition(border 0.15s ease-in-out);
    }

    &::after {
      display: inline-block;
      position: absolute;
      content: ' ';
      width: 11px;
      height: 11px;
      left: 3px;
      top: 3px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $input-color;
      @include scale(0, 0);

      @include transition-transform(0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33));
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }

  input[type='radio'] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus + label::before {
      @include tab-focus();
    }

    &:checked + label::after {
      @include scale(1, 1);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled + label {
      opacity: 0.65;

      &::before {
        cursor: not-allowed;
      }
    }
  }

  &.radio-inline {
    margin-top: 0;
  }
}

@include radio-variant('.radio-primary', $brand-primary);
@include radio-variant('.radio-danger', $brand-danger);
@include radio-variant('.radio-info', $brand-info);
@include radio-variant('.radio-warning', $brand-warning);
@include radio-variant('.radio-success', $brand-success);

input[type='checkbox'],
input[type='radio'] {
  &.styled:checked + label:after {
    font-family: $font-family-icon;
    content: $check-icon;
  }
  .styled:checked + label {
    &::before {
      color: var(--color-white);
    }
    &::after {
      color: var(--color-white);
    }
  }
}

.toggle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  .toggle {
    display: flex;
    align-items: center;
    input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
      visibility: visible;
      white-space: nowrap;
      + label {
        position: relative;
        display: block;
        width: 30px;
        height: 20px;
        margin-bottom: 0;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transform: translate3d(0, 0, 0);
        &:before {
          content: '';
          position: relative;
          top: 4px;
          left: 3px;
          width: 25px;
          height: 11px;
          display: block;
          background: var(--color-text-l2);
          border-radius: 8px;
          transition: background 0.2s ease;
        }
        span {
          position: absolute;
          top: 2px;
          left: 0;
          width: 15px;
          height: 15px;
          display: block;
          background: var(--color-white);
          border-radius: 10px;
          box-shadow: 0 3px 8px var(--color-shadow);
          transition: all 0.2s ease;
          &:before {
            content: '';
            position: absolute;
            display: block;
            margin: -18px;
            width: 56px;
            height: 56px;
            background: var(--color-background-toggle);
            border-radius: 50%;
            transform: scale(0);
            opacity: 1;
            pointer-events: none;
          }
        }
      }
      &:checked {
        + label {
          &:before {
            background: var(--color-background-toggle);
          }
          span {
            background: var(--color-primary);
            transform: translateX(15px);
            transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25),
              background 0.15s ease;
            box-shadow: 0 3px 8px var(--color-shadow);
          }
          + .toggle-label {
            color: var(--color-text-l1);
          }
        }
      }
      &:disabled {
        + label {
          filter: grayscale(1);
          cursor: not-allowed;
        }
      }
    }
    .center {
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 20px);
    }
    &-label {
      font-size: 11px;
      line-height: 1em;
      font-weight: 600;
      padding: 0 3px;
      color: var(--color-text-l2);
    }
  }
}
