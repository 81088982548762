.trading-report {
  &-section {
    display: flex;
    flex-shrink: 0;
  }
  &-datepicker {
    display: grid;
    border-radius: 2px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    padding: 8px 16px;
    column-gap: 8px;
    color: var(--color-text-l1);
    cursor: pointer;
    &.react-datepicker-ignore-onclickoutside {
      color: var(--color-primary);
    }
    .mdi-calendar {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      display: grid;
      justify-content: center;
      align-items: center;
    }
    &-label {
      grid-column: 2/3;
      grid-row: 1 / 2;
      font-size: 10px;
      color: var(--color-text-l1);
    }

    &-input {
      all: unset;
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      font-weight: bold;
    }
    &-wrapper {
      width: 50%;
      border: 1px solid var(--color-divider);
      border-radius: 4px;
      display: grid;
      grid-template-columns: auto auto;
      position: relative;
      column-gap: 1px;
      margin: 8px 0 16px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      @media screen and (min-width: 768px) and (max-width: 992px) {
        width: 80%;
      }
      @media screen and (min-width: 993px) and (max-width: 1160px) {
        width: 70%;
      }
      &:after {
        content: '';
        height: 100%;
        width: 1px;
        background: var(--color-background-l1);
        position: absolute;
        left: 50%;
      }
    }
  }
}
