.faq {
  background: var(--color-background-l0);
  width: 100%;
  display: flex;
  &-wrapper {
    width: 100%;
    max-width: 650px;
    margin: 64px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    @media screen and (max-width: 767px) {
      margin: 48px auto;
    }
  }
  &-help {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 16px 0;
    &,
    a {
      font-size: 16px;
    }
    @media screen and (max-width: 767px) {
      padding: 8px 0;
      &,
      a {
        font-size: 14px;
      }
    }
  }
  &-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-heading {
      display: flex;
      justify-content: center;
      padding: 8px 0;
      &-title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
    }
    &-panel {
      &-group {
        width: 100%;
      }
      &-item {
        border-radius: 4px;
        border: solid 1px var(--color-divider);
        margin: 5px 0;
        &-title a {
          display: flex;
          padding: 25px;
          flex-grow: 1;
          justify-content: space-between;
          color: var(--color-text-l1);
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
        &-body {
          font-size: 14px;
          padding: 0 25px 25px;
          color: var(--color-text-l2);
          line-height: 20px;
          ul {
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
