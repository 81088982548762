.payment-option {
  display: flex;
  flex-direction: column;
  .info-box {
    padding: 16px 16px 0;
    width: 100%;
  }
  &-form {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
  &-fields {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row-gap: 24px;
    &-item {
      &:last-child {
        margin: 0;
      }
    }
  }
  &-cta-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
  }
  &-currency {
    &-list {
      display: inline-flex;
      margin-bottom: 8px;
      margin-top: 1px;
      border-radius: 2px;
      overflow: hidden;
    }
    &-item {
      background: var(--color-background-l0);
      padding: 8px 16px;
      &:not(:last-child) {
        border-right: 1px solid var(--color-divider);
      }
      &.selected {
        background: var(--color-primary);
        color: var(--color-background-l0);
      }
      &-text {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 700;
      }
    }
  }

  &-status-box {
    padding: 16px;
    display: flex;
    margin: 0 14px;
    background: var(--color-background-danger);
    border-radius: 2px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    &.verified,
    &.partial_verified {
      background: var(--color-background-success);
      .title {
        color: var(--color-green);
      }
    }
    &.rejected {
      background: var(--color-background-danger);
      .title {
        color: var(--color-red);
      }
    }
    &.unverified {
      background: var(--color-background-danger);
      .title {
        color: var(--color-red);
      }
    }
    &.verifying,
    &.waiting_for_kyc,
    &.requested {
      background: var(--color-background-warning);
      .title {
        color: var(--color-text-l1);
      }
    }

    > .content {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      width: 100%;
      > .title {
        font-size: 14px;
        line-height: 1.1em;
        letter-spacing: 0.2px;
        font-weight: bold;
      }
      > .description {
        font-size: 12px;
        padding-top: 8px;
      }
    }

    > .cta {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      flex-shrink: 0;
      margin-left: 12px;
      @media screen and (max-width: 767px) {
        margin-top: 8px;
      }
      &-text {
        line-height: 1em;
      }
    }
  }
}
