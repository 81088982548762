.prefs {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  @media only screen and (max-width: 767px) {
    margin: 0 auto;
    height: 100%;
  }
  &-header {
    display: flex;
    height: 72px;
    padding: 0 66px;
    background: var(--color-primary);
    align-items: center;
    justify-content: center;

    box-shadow: 0 1px 2px 0 var(--color-shadow);
    color: var(--color-white);
    flex-shrink: 0;
    @media screen and (min-width: 768px) {
      border-radius: 4px 4px 0 0;
    }
  }
  &-title {
    font-size: 24px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  &-body {
    position: relative;
    box-shadow: 0 2px 4px 0 var(--color-shadow);
    background: var(--color-background-l0);
    border-radius: 4px;
    margin-bottom: 12px;
  }
  &-subtitle {
    padding: 17px 24px;
    border-bottom: 1px solid var(--color-divider);
    &-text {
      font-size: 14px;
      letter-spacing: 0.5px;
      font-weight: 700;
    }
  }
  &-list {
    &-loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 150px;
      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
      }
      &-text {
        text-align: center;
        font-size: 12px;
        color: var(--color-text-l2);
        font-weight: 400;
        text-transform: none;
      }
    }
    &-item {
      display: flex;
      justify-content: space-between;
      padding: 15px 24px;
      flex-shrink: 0;
      align-items: center;
      &.variant-2 {
        padding: 12px 24px;
      }
      &:not(:last-child) {
        //border-bottom: 1px solid rgba(38,50,56,0.12);
      }
      &-text-title {
        font-weight: 600;
        display: flex;
        align-items: center;
        &.baseline {
          align-items: baseline;
        }
        &-note {
          color: var(--color-green);
          font-size: 10px;
          padding: 0 8px;
          line-height: 1.2em;
        }
      }
      &-text-desc {
        color: var(--color-text-l2);
        font-size: 12px;
        &.medium {
          color: var(--color-background-warning);
        }
        &.high {
          color: var(--color-green);
        }
        &.none {
          color: var(--color-red);
        }
      }
      &-text-status {
        color: var(--color-text-l2);
        letter-spacing: 0.5px;
        font-size: 12px;
        &.info {
          color: var(--color-text-l1);
        }
        &.warning {
          color: var(--color-background-warning);
        }
        &.success {
          color: var(--color-green);
        }
        &.error {
          color: var(--color-red);
        }
      }
      &-link {
        &-title {
          cursor: pointer;
          font-size: 14px;
          line-height: 1.67;
          color: var(--color-red);
          text-decoration: none;
          letter-spacing: 0.5px;
          font-weight: 700;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &-state {
        color: var(--color-primary);
        display: flex;
        min-height: 25px;
        &.top {
          align-self: flex-start;
        }
      }
      &.selectable {
        &:hover {
          background: var(--color-background-l1);
          cursor: pointer;
        }
      }
    }
  }
  &-note {
    padding: 12px 24px;
    &-text {
      color: var(--color-text-l1);
      text-align: left;
      letter-spacing: 0.5px;
      line-height: 1.2em;
      font-size: 14px;
    }
  }
}
.trading-fee {
  &-table {
    padding: 24px;
    &-description {
      color: var(--color-text-l2);
      letter-spacing: 0.5px;
      font-size: 12px;
      padding-bottom: 8px;
    }
    &-row {
      display: flex;
      &-header {
        font-weight: 700;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-left: 1px solid var(--color-divider);
      border-top: 1px solid var(--color-divider);
    }
  }
  &-col {
    flex: 1;
    border-bottom: 1px solid var(--color-divider);
    border-right: 1px solid var(--color-divider);
    padding: 8px;
    &-text {
      color: var(--color-text-l2);
      font-size: 12px;
    }
  }
}
