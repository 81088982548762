.mine-wrx {
  display: inline-flex;
  flex-direction: column;
  background-color: var(--color-background-l1);
  padding: 0 0 75px 0;
  width: 100%;
  flex-shrink: 0;
  .section {
    display: inline-flex;
    flex-direction: column;
    width: 75%;
    align-self: center;
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 15px;
    }
    &-hero {
      background-color: var(--color-landing-hero-background);
      height: 370px;
      border-bottom: 1px solid transparent;
      position: relative;
      padding: 0 20px;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 100px;
      z-index: 1;
      flex-shrink: 0;
      @media screen and (max-width: 767px) {
        height: 260px;
        &:not(.logged-in) {
          margin-bottom: 125px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 60%;

        background: url(../images/hero-pattern-left.png) no-repeat;
        background-size: contain;
        background-position: left top;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 60%;
        background: url(../images/hero-pattern-right.png) no-repeat;
        background-position: right top;
        background-size: contain;
      }
      &-wrapper {
        color: var(--color-white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }
      &-coin-logo {
        width: 152px;
        height: 150px;
        @media screen and (max-width: 767px) {
          width: 81px;
          height: 80px;
        }
      }
      &-login {
        display: flex;
        justify-content: center;
        z-index: 3;
        flex-shrink: 0;
      }
      &-title {
        font-size: 24px;
        font-weight: 900;
      }

      &-description {
        font-size: 15px;
        font-weight: 500;
        opacity: 0.8;
        padding: 10px 0;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          font-weight: 700;
          opacity: 0.6;
        }
      }
      &-curtain {
        padding: 50px;
        -webkit-clip-path: ellipse(55% 100% at 50% 0%);
        clip-path: ellipse(55% 100% at 50% 0%);
        position: absolute;
        background: var(--color-landing-hero-background);
        width: 100%;
        left: 0;
        bottom: -25%;
      }
      + .section {
        @media screen and (min-width: 768px) {
          margin-top: 50px;
        }
        z-index: 2;
      }
    }
    &-mininfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      &-desc {
        display: flex;
        justify-content: space-between;
        padding: 0 0 35px 0;
        width: 100%;
        flex-shrink: 0;
        @media screen and (max-width: 767px) {
          flex-direction: column;
          padding-bottom: 0;
          padding-top: 0;
        }
        &-box {
          display: flex;
          flex-direction: column;
          width: 30%;
          //height: 160px;
          background-color: var(--color-background-l0);
          border: solid 1px var(--color-primary);
          align-items: center;
          text-align: center;
          border-radius: 8px;
          box-shadow: 0px 30px 35px -18px var(--color-shadow);
          position: relative;
          overflow: hidden;
          flex-shrink: 0;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 15px;
            box-shadow: 0px 15px 20px -10px var(--color-shadow);
          }
          .title {
            font-size: 34px;
            font-weight: 700;
            line-height: 1em;
            padding-top: 35px;
            padding-left: 10px;
            padding-right: 10px;
            @media screen and (max-width: 767px) {
              font-size: 24px;
              padding-top: 15px;
            }
          }
          .description {
            width: 80%;
            padding-top: 10px;
            opacity: 0.5;
            font-size: 14px;
            padding-bottom: 35px;
            @media screen and (max-width: 767px) {
              font-size: 12px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
          }
          .box-icon {
            position: absolute;
            bottom: 0;
            height: 51px;
            right: 0;
            width: 51px;
          }
        }
      }
      &-live {
        background: var(--color-red);
        border-radius: 4px;
        padding: 15px 5px;
        color: var(--color-white);
        width: 100%;
        text-align: center;
        a {
          color: var(--color-white);
          font-weight: bold;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &-user {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      background-color: var(--color-white);
      box-shadow: 0px 30px 35px -18px var(--color-shadow);
      border-radius: 8px;
      margin-bottom: 35px;
      padding-top: 25px;
      height: auto;
      flex-shrink: 0;
      @media screen and (max-width: 767px) {
        padding: 0;
        width: calc(100% - 30px);
        height: auto;
      }
      &-today {
        font-size: 16px;
        font-weight: 700;
        color: var(--color-primary);
        text-transform: uppercase;
        letter-spacing: 0.5px;
        @media screen and (max-width: 767px) {
          margin: 15px 0 0;
        }
      }
      &-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        flex-direction: column;
        flex-shrink: 0;
        @media screen and (max-width: 767px) {
          width: 95%;
        }
        &-partition {
          padding: 25px 15px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-shrink: 0;
          &.partition-1 {
            padding-bottom: 0;
            &::after {
              content: '';
              width: 100%;
              height: 1px;
              background: var(--color-shadow);
              display: block;
              margin: 20px auto 0;
            }
          }
          .title {
            font-size: 14px;
            font-weight: 700;
            color: var(--color-text-l1);
            text-transform: uppercase;
            @media screen and (max-width: 767px) {
              font-size: 12px;
              padding-bottom: 5px;
            }
          }
          .numbers {
            font-size: 34px;
            font-weight: 700;
            line-height: 1em;
            padding: 5px 0;
            @media screen and (max-width: 767px) {
              font-size: 24px;
            }
          }

          &.partition-2 {
            .numbers {
              font-size: 18px;
              position: relative;
              padding: 5px 20px;
              @media screen and (max-width: 767px) {
                padding: 3px 15px;
              }

              &:not(:last-of-type) {
                &::after {
                  content: '';
                  background: var(--color-shadow);
                  @media screen and (min-width: 768px) {
                    width: 1px;
                    height: 100%;
                    right: 0;
                    top: 0;
                    position: absolute;
                  }

                  @media screen and (max-width: 767px) {
                    width: 50px;
                    height: 1px;
                    display: block;
                    margin: 6px auto 0;
                  }
                }
              }
            }
            .numbers-container {
              padding-top: 5px;
              display: flex;
              align-items: center;
              flex-shrink: 0;
              @media screen and (max-width: 767px) {
                flex-direction: column;
              }
            }
          }
        }
      }
      &-next-slab {
        background-color: var(--color-green);
        color: var(--color-white);
        padding: 10px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 1.2em;
        justify-self: end;
        align-self: center;
        margin-top: auto;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    &-history,
    &-trading-slabs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      padding: 0 30px;
      flex-shrink: 0;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0 15px;
      }
      &-title {
        padding: 60px 0 25px 0;
        font-size: 16px;
        font-weight: 700;
        color: black;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        text-align: center;
        @media screen and (max-width: 767px) {
          padding: 40px 0 15px 0;
          font-size: 15px;
        }
      }
      &-table {
        width: 100%;
        table {
          margin-bottom: 0 !important;
        }
        tr {
          background-color: var(--color-table-row-even);
          &:not(:last-child) {
            border-bottom: 1px solid var(--color-divider);
          }
          &:nth-child(even) {
            background-color: var(--color-table-row-even);
          }
          &.total {
            background-color: var(--color-green);
            color: var(--color-white);
          }
        }
        th,
        td {
          padding: 15px 20px;

          font-size: 14px;
          @media screen and (max-width: 767px) {
            padding: 8px 14px;
            font-size: 11px;
          }
        }
        th {
          color: var(--color-text-l2);
          text-transform: uppercase;
        }
      }
      &-toggle {
        width: 100%;
        &-group {
          width: 100%;
          display: flex;
          margin-bottom: 16px;
          flex-shrink: 0;
          @media screen and (max-width: 767px) {
            margin-bottom: 12px;
          }
        }
        &-item {
          flex-grow: 1;
          font-size: 18px;
          font-weight: 400;
          box-shadow: none;
          opacity: 0.3;
          position: relative;
          &.active {
            opacity: 1;
            font-weight: 700;

            &:after {
              content: '';
              height: 4px;
              width: 100%;
              display: block;
              background: var(--color-primary);
              position: absolute;
              left: 0;
              border-radius: 16px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
