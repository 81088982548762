.toggle-info {
  width: 100%;
  &-group {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      margin-bottom: 12px;
    }
  }
  &-item {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 400;
    box-shadow: none !important;
    opacity: 0.3;
    position: relative;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
    &.active {
      opacity: 1;
      font-weight: 700;
      &:after {
        content: '';
        height: 4px;
        width: 100%;
        display: block;
        background: var(--color-primary);
        position: absolute;
        left: 0;
        border-radius: 16px;
        margin-top: 5px;
      }
    }
  }
}
