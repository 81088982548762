.close-popover {
  background: var(--color-black);
  border-radius: 4px;
  .popover-content {
    padding: 2px 16px;
    background: var(--color-black);
    color: var(--color-white);
  }
}
.close-popover.top > .arrow {
  bottom: -7px;
  border-top-color: transparent;
  &:after {
    border-top-color: var(--color-black);
    margin-left: 0;
  }
}

.close-popover.left > .arrow {
  border-left-color: transparent;
  right: -8px;
  &:after {
    border-left-color: var(--color-black);
    margin-left: -6px;
  }
}

.close-popover.bottom > .arrow {
  border-bottom-color: transparent;
  top: -8px;
  &:after {
    border-bottom-color: var(--color-black);
    margin-left: 0;
  }
}
