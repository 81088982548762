.unlock-program {
  display: grid;
  > .hero {
    background: var(--color-background-hero);
    display: grid;
    justify-items: center;
    box-shadow: 0px 1px 1px 1px var(--color-shadow);
    padding: 24px 48px;
    margin-bottom: 24px;
    position: relative;
    @media screen and (min-width: 1100px) {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        background: url(../images/unlock-illustration-left.svg) no-repeat;
        height: 100%;
        width: 178px;
        margin: auto;
        background-position: center;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        background: url(../images/unlock-illustration-right.svg) no-repeat;
        height: 100%;
        width: 198px;
        margin: auto;
        background-position: center;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 16px 12px;
      margin-bottom: 12px;
    }
    > .illustration {
      display: none;
      @media screen and (max-width: 1099px) {
        display: block;
        margin-bottom: 24px;
      }
    }
    > .title {
      margin: 40px 0;
      color: var(--color-text-l1);
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
      @media screen and (max-width: 1099px) {
        margin: 24px 0;
      }
    }
    > .wrx-unlock-table {
      display: grid;
      max-width: 650px;
      width: 100%;
      border: 4px solid var(--color-primary);
      border-radius: 4px;
      margin: 0 0 40px;
      color: var(--color-text-l1);
      position: relative;
      > .overlay {
        position: absolute;
        opacity: 1;
        background-color: var(--color-primary);
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        bottom: 0;
        justify-content: center;
        align-items: center;
        > .subtext {
          font-size: 14px;
          line-height: 1.33;
          color: var(--color-white);
          font-weight: bold;
        }
      }
      > .header {
        grid-row: 1;
        background: var(--color-primary);
        display: grid;
        //grid-template-columns: calc(33% - 58px) 33% calc(34% + 58px);
        grid-template-columns: repeat(3, 1fr);
        padding: 24px 32px;
        column-gap: 4px;
        @media screen and (max-width: 767px) {
          //grid-template-columns: calc(33% - 28px) 33% calc(34% + 28px);
          padding: 8px 16px;
        }
        > .cell {
          display: grid;
          grid-template-columns: minmax(min-content, 1fr);
          > .cell-container {
            display: grid;
            padding-left: 50px;
            position: relative;
            min-height: 50px;
            text-align: right;
            grid-template-rows: min-content;
            @media screen and (max-width: 767px) {
              padding-left: 0;
            }
            &:before {
              content: '';
              position: absolute;
              left: 8px;
              width: 50px;
              height: 50px;
              top: 0px;
            }
            @media screen and (max-width: 767px) {
              min-height: 24px;
              &:before {
                display: none;
              }
            }

            > .title {
              color: var(--color-white);
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              @media screen and (max-width: 767px) {
                font-size: 12px;
              }
            }
            > .amount {
              font-size: 24px;
              line-height: 1.33em;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              color: var(--color-white);
              @media screen and (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
          &:nth-child(2) {
            justify-self: end;
            .cell-container {
              &:before {
                background: url(../images/wrx-earned-icon.svg);
                background-size: contain;
                left: 8px;
                width: 38px;
                height: 39px;
                top: 5.5px;
              }
            }
          }
          &:last-child {
            justify-self: end;
            .cell-container {
              &:before {
                background: url(../images/wrx-unlocked-icon.svg);
                background-size: contain;
                left: 8px;
                width: 38px;
                height: 35px;
                top: 10.5px;
              }
            }
          }
        }
      }
      > .body {
        grid-row: 2;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 4px;
        row-gap: 4px;
        padding: 0 32px;
        @media screen and (max-width: 767px) {
          padding: 0 16px;
        }
        .body {
          &-row {
            position: relative;
            grid-column: 1 / span 3;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @media screen and (min-width: 768px) {
              &:not(:last-child) {
                &:after {
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 4px;
                  left: 0;
                  bottom: -4px;
                  background: var(--color-primary);
                }
              }
            }
          }
          &-cell {
            padding: 30px 0;
            font-size: 16px;
            line-height: 1.25em;
            text-align: center;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            justify-content: flex-end;
            text-align: right;
            @media screen and (max-width: 767px) {
              font-size: 12px;
              padding: 10px 0;
            }
            &:first-child {
              justify-content: flex-start;
              text-align: left;
            }

            &:not(:first-child) {
              font-weight: bold;
            }
            > .breakdown {
              &-icon {
                margin-right: 8px;
              }
              &-total,
              &-unlocked {
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }

  > .breakdown {
    display: grid;
    padding: 24px 48px;
    overflow: hidden;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    row-gap: 12px;
    column-gap: 12px;
    height: 100%;
    @media screen and (max-width: 767px) {
      padding: 24px 12px;
    }
    > .card {
      background-color: var(--color-background-l0);
      box-shadow: 1px 1px 1px 1px var(--color-shadow);
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: flex-start;
      position: relative;
      border-radius: 8px;
      padding-top: 70px;
      @media screen and (max-width: 767px) {
        padding-top: 50px;
      }
      > .tag {
        position: absolute;
        right: 20px;
        top: 0;
        border-bottom-left-radius: 35px;
        border-bottom-right-radius: 35px;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        @media screen and (max-width: 767px) {
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
          width: 50px;
          height: 50px;
        }
      }
      > .title {
        padding-left: 20px;
        text-transform: uppercase;
        color: var(--color-text-l2);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.7px;
        margin-bottom: 8px;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      > .unlocked {
        padding-left: 20px;
        padding-right: 20px;
        color: var(--color-text-l1);
        font-weight: bold;
        font-size: 21px;
        line-height: 1.42em;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }

      > .bar {
        width: 80%;
        height: 15px;
        margin-bottom: 16px;
        margin-left: 20px;
        > .progress {
          height: 10px;
          box-shadow: none;
          margin-bottom: 0;
        }
      }

      > .description {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        font-size: 14px;
        line-height: 1.71em;
        color: var(--color-text-l1);
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      &.initial {
        > .tag {
          background-color: var(--color-background-l1);
          svg {
            fill: var(--color-primary);
          }
        }
        > .bar > .progress {
          background-color: var(--color-background-l1);
          > .progress-bar {
            background-color: var(--color-primary);
          }
        }
      }
      &.fee {
        > .tag {
          background-color: var(--color-background-danger);
          svg {
            fill: var(--color-red);
          }
        }
        > .bar > .progress {
          background-color: var(--color-background-danger);
          > .progress-bar {
            background-color: var(--color-red);
          }
        }
      }
      &.vesting {
        > .tag {
          background-color: var(--color-background-success);
          svg {
            fill: var(--color-green);
          }
        }
        > .bar > .progress {
          background-color: var(--color-background-success);
          > .progress-bar {
            background-color: var(--color-green);
          }
        }
      }
      &.trading {
        > .tag {
          background-color: var(--color-background-l1);
          svg {
            fill: var(--color-primary);
          }
        }
        > .bar > .progress {
          background-color: var(--color-background-l1);
          > .progress-bar {
            fill: var(--color-primary);
          }
        }
      }
    }
  }

  > .schedule,
  > .history {
    display: grid;
    padding: 24px 48px;
    @media screen and (max-width: 767px) {
      padding: 8px 12px;
    }
    > .title {
      text-align: center;
      margin-bottom: 30px;
      font-weight: bold;
      color: var(--color-text-l2);
      margin-top: 0;
      font-size: 30px;
      @media screen and (max-width: 767px) {
        margin-bottom: 15px;
        font-size: 24px;
      }
    }

    > .schedule,
    > .history {
      &-table {
        width: auto !important;
        table {
          margin-bottom: 0 !important;
          border: 1px solid var(--color-divider);
          font-size: 14px;
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
          > thead > tr,
          > tbody > tr {
            background-color: var(--color-table-row-odd);
            &:not(:last-child) {
              border-bottom: 1px solid var(--color-divider);
            }
            &:nth-child(even) {
              background-color: var(--color-table-row-even);
            }

            > th {
              text-transform: uppercase;
              border: 1px solid var(--color-divider);
              vertical-align: middle;
              text-align: center;
              background-color: var(--color-background-l0);
            }
            > td {
              padding: 12px 14px;
              > .loader {
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex-shrink: 0;
              }
            }

            &.main-header {
              > th {
                font-weight: bold;
                color: var(--color-text-l1);
              }
            }
            &.sub-header {
              > th {
                font-weight: 400;
                color: var(--color-text-l1);
              }
            }
          }
        }
      }
    }
  }
  > .history {
    padding-bottom: 48px;
    @media screen and (max-width: 767px) {
      padding-bottom: 24px;
    }
  }
}
