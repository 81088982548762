.ticker {
  &-container {
    display: flex;
    align-self: flex-start;
    border-radius: 4px;
    background-color: var(--color-background-l0);
    box-shadow: 1px 1px 2px var(--color-shadow);
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (max-width: 992px) {
      margin-bottom: 12px;
    }
  }
  &-wrapper {
    display: flex;
    padding: 10px 10px 0;
    width: 100%;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
    font-weight: bold;
  }
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 94px;
    box-shadow: inset 0 -1px 0 0 var(--color-shadow);
    background-color: var(--color-background-l0);
    border-radius: 4px;
  }
  &-body {
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 3px;
      background-color: var(--color-scrollbar-background);
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--color-scrollbar-thumb);
    }
  }
  &-toggle {
    display: flex;
    flex: 0 0 32px;
    position: relative;
    padding: 8px 0;
    width: 100%;
    margin-bottom: 4px;
  }
  &-loader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 68px 0;
    background-color: var(--color-background-l0);
    height: 100%;
    .title {
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: 0.4px;
    }
  }
  &-item {
    display: flex;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid var(--color-divider);
    padding: 4px 12px;
    align-items: center;
    flex-grow: 0;
    background-color: transparent;
    .currency-logo {
      margin-right: 8px;
      font-size: 8px;
    }

    .market {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      &-name {
        display: flex;
        flex-direction: column;
        color: var(--color-text-l0);
        font-weight: bold;
        &-text {
          text-transform: uppercase; //uppercase
          font-weight: bold; //bold
          font-size: 12px; //medium
          line-height: 1.67;
          letter-spacing: 0.4px;
          color: var(--color-text-l0);
          .subtext {
            color: var(--color-text-l2);
            font-size: 10px;
            line-height: 1.2;
            text-align: right;
            font-weight: bold;
          }
        }
      }
      &-change {
        display: flex;
      }
    }

    .price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      color: var(--color-text-l0);
      z-index: 1;

      .blinkGreen {
        animation: blinkGreen 0.5s ease-out 1;
      }

      .blinkRed {
        animation: blinkRed 0.5s ease-out 1;
      }
      &-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      &-text {
        font-weight: bold;
        color: var(--color-text-l0);
        text-align: right;
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.4px;
      }

      &-subtext {
        font-weight: bold;
        font-size: 10px;
        line-height: 1.2;
        text-align: right;
        color: var(--color-text-l3);
      }
    }

    &:hover {
      background-color: var(--color-background-hover);
    }
    &:last-of-type {
      margin-bottom: 6px;
      border-bottom: none;
    }
    &.selected {
      background-color: var(--color-background-activated);
      box-shadow: inset 3px 0 0 0 var(--color-primary);
    }
  }
}

.theme-dark .ticker-item,
.theme-dark .record {
  .currency-logo {
    filter: saturate(0) brightness(1.75);
  }
}
