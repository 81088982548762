.notificationContainer {
  position: absolute;
  z-index: 1000;
  right: 50%;
  transform: translateX(50%);
  pointer-events: none;
  padding: 0;
  transition: top 0.3s ease-in;
  &--show {
    top: 0;
  }
  &--hide {
    top: -56px;
  }

  .notification {
    background: var(--color-gray);
    color: var(--color-white);
    font-weight: bold;
    text-align: center;
    min-height: 56px;
    pointer-events: initial;
    &--success {
      background: var(--color-green);
    }

    &--error {
      background: var(--color-red);
    }

    &__toastr {
      padding: 5px 50px;
      max-width: 450px;
    }
  }
}
