.contest {
  margin-top: 16px;
  .card {
    border-radius: 8px;
    box-shadow: 1px 1px 2px var(--color-shadow);
    position: relative;
    padding: 0 0 16px;
    &-header {
      height: auto !important;
      padding: 2px 16px;
      justify-content: center;
      text-transform: capitalize;
      &.Active {
        color: var(--color-white);
        background: var(--color-green);
      }
      &.Expired {
        color: var(--color-white);
        background: var(--color-gray);
      }
      &.Scheduled {
        color: var(--color-white);
        background: var(--color-primary);
      }
    }
    &-coin {
      color: var(--color-text-l2);
      font-weight: bolder;
      text-transform: uppercase;
    }
    &-status {
      font-size: 12px;
      font-weight: bolder;
    }
    &-description {
      font-size: 12px;
      padding: 0 16px;
      p {
        line-height: 1.33em;
        margin: 8px 0px;
      }
      ol,
      ul {
        margin-left: 15px;
      }
    }
  }
  .hero {
    background-size: cover;
    width: 100%;
    height: auto;
  }
  .table-results {
    width: 100%;
    table-layout: fixed;
    th,
    td {
      padding: 4px 16px;
    }
    thead {
      td {
        text-transform: capitalize;
      }
    }
    tbody {
      border-top: 1px solid var(--color-divider);
      tr {
        height: 46px;
        padding: 5px 16px;
        position: relative;
        &.login-row {
          padding: 0;
          td {
            padding: 0;
          }
        }
        &.userrank {
          background: var(--color-background-l0);
          .userrank-marker {
            position: absolute;
            background: var(--color-background-warning);
            border: 0;
            z-index: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            font-weight: bold;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            padding: 2px 12px;
            position: absolute;
            left: 46px;
          }
          td {
            position: relative;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid var(--color-divider);
        }
        .rank-circle {
          height: 35px;
          width: 35px;
          border-radius: 50%;
          color: var(--color-text-l2);
          font-weight: 900;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--color-divider);
          position: relative;
          z-index: 5;
          background: var(--color-background-l0);
        }
      }
    }
  }
}
