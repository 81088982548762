.form-input {
  padding: 12px 16px;
  border: solid 1px var(--color-divider);
  width: 100%;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: var(--color-text-l1);
  transition: all 0.4s;
  border-radius: 4px;
  background-color: var(--color-background-input);
  &:focus {
    background-color: var(--color-background-input);
    border: solid 1px var(--color-primary);
    outline: 0;
    border-radius: 4px;
  }
  &.country-code {
    padding-left: 22px;
    &:disabled {
      background: var(--color-divider);
    }
  }
}
