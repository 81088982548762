.p2p-order-book {
  &__header {
    &__title {
      color: var(--color-text-l0);
      font-size: 12px;
      line-height: 1.2em;
      letter-spacing: 0.4px;
      text-align: left;
      font-weight: 400;
      padding: 8px 4px;
    }
    &__types {
      &.toggle-group {
        .btn {
          padding: 12px 20px 12px;
        }
      }
    }
  }
  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__menu {
    border-bottom: 1px solid var(--color-divider);
    .menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__title {
        font-size: 12px;
        line-height: 1.67em;
        color: var(--color-text-l1);
        padding: 0 4px;
      }
      &__options {
        width: 100%;
        .btn {
          font-size: 12px;
          font-weight: 700;
          white-space: pre-line;
          width: 50%;
          padding: 12px;
          &-default {
            &,
            &:hover,
            &:active,
            &:focus {
              background: var(--color-background-l1);
              border: none;
              color: var(--color-text-l0);
              box-shadow: none;
            }
            &.active {
              &,
              &:hover {
                background: var(--color-background-l0);
                box-shadow: none;
                color: var(--color-green);
                position: relative;
                &:after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 2px;
                  width: 100%;
                  background-color: var(--color-green);
                }
              }
            }
          }
        }
      }
    }
  }
  &__table {
    display: flex;
    position: relative;
    overflow-y: auto;
  }
}
