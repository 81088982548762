.tab-box {
  &-nav {
    display: flex;
    justify-content: space-between;
    padding: 0 0 12px;
    &-link {
      font-size: 14px;
      color: var(--color-text-l2);
      cursor: pointer;
      &:hover {
        color: var(--color-text-l1);
      }
    }
  }
  &-wrapper {
    @media screen and (min-width: 768px) {
      max-width: 464px;
    }
    &.with-back {
      margin: 0 auto;
      padding: 15px;
    }
  }
  &-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 250px;
  }
  .tab {
    &-container {
      border-radius: 4px;
      background-color: var(--color-background-l0);
      overflow: hidden;
      box-shadow: 1px 1px 2px var(--color-shadow);
      height: auto;
    }
    &-panel {
      flex-grow: 1;
      &-container {
        display: flex;
        overflow: auto;
      }
    }
    &-list {
      display: flex;
      flex-shrink: 0;
      &-item {
        display: flex;
        flex: 1 1 auto;
        background-color: var(--color-background-activated);
        box-shadow: inset 0 -1px 0 0 var(--color-shadow);
        padding: 10px 12px 8px;
        justify-content: center;
        cursor: pointer;
        @media screen and (max-width: 767px) {
          padding: 13px 12px 10px;
        }
        &:not(:first-child) {
          border-left: 1px solid var(--color-shadow);
        }
        &.blinkGreen {
          animation: blinkGreen 0.5s ease-out 1;
        }

        &.blinkRed {
          animation: blinkRed 0.5s ease-out 1;
        }
        &.selected {
          background-color: var(--color-background-l0);
          box-shadow: inset 0 4px 0 0 var(--color-green);
          @media screen and (max-width: 767px) {
            box-shadow: inset 0 5px 0 0 var(--color-green);
          }
          &.buy {
            box-shadow: inset 0 2px 0 0 var(--color-green);
            @media screen and (max-width: 767px) {
              box-shadow: inset 0 5px 0 0 var(--color-green);
            }
          }
          &.sell {
            box-shadow: inset 0 2px 0 0 var(--color-red);
            @media screen and (max-width: 767px) {
              box-shadow: inset 0 5px 0 0 var(--color-red);
            }
          }

          .tab-list-item-title {
            color: var(--color-text-l0);
          }
        }
        &-title {
          color: var(--color-text-l1);
          font-size: 11px;
          line-height: 1.67;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          text-align: center;
          font-weight: bold;
          line-height: 1.33;
        }
      }
    }
  }
  &-responsive {
    display: flex;
    flex-grow: 1;
    .tab {
      &-container {
        box-shadow: none;
        border-radius: 0;
      }
    }
  }
}
