$base-px-value: 8px;

//margin helpers
.m-t-3x {
  margin-top: 3 * $base-px-value;
}
.m-b-3x {
  margin-bottom: 3 * $base-px-value;
}
.m-b-2x {
  margin-bottom: 2 * $base-px-value;
}
.m-b-4x {
  margin-bottom: 4 * $base-px-value;
}

.cur-ptr {
  cursor: pointer;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-l3);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-l3);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-l3);
}

.row-no-padding {
  [class*='col-'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.danger {
  color: 1px var(--color-red);
}

.text-large {
  font-size: 14px !important;
  line-height: 1.43;
}

.text-bold {
  font-weight: bold;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.currency-input {
  border-radius: 4px;
  overflow: hidden;
  font-weight: 600;
  padding: 10px 35px 10px 75px;
  width: 100%;
  font-size: 14px;
  line-height: 1.25;
  -webkit-letter-spacing: 0.2px;
  -moz-letter-spacing: 0.2px;
  -ms-letter-spacing: 0.2px;
  letter-spacing: 0.2px;
  border: solid 1px 1px var(--color-divider);
  color: 1px var(--color-text-l1);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  &:focus {
    background-color: 1px var(--color-background-input);
    border: solid 1px 1px var(--color-divider);
    outline: 0;
    border-radius: 4px;
  }
}

.dummy-css {
  position: relative;
}

.infield-link {
  // on click of this class, we want little user feedback that click has been registered
  &:active {
    opacity: 0.7;
  }
}
