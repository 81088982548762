.downloads {
  display: inline-flex;
  flex-direction: column;
  background-color: var(--color-background-default);
  flex-shrink: 0;
  width: 100%;
  &-section {
    display: inline-flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 75%;
    padding: 0 36px;
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
      padding: 0 15px;
      width: 100%;
    }
  }
  &-hero {
    width: 100%;
    position: relative;
    justify-content: space-between;
    flex-shrink: 0;
    z-index: 3;
    padding: 36px 36px 0;
    margin-bottom: 115px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      padding: 40px 10px 0;
      margin-bottom: 40px;
    }
    @media screen and (max-width: 374px) {
      padding: 40px 0 0;
    }
    &:before {
      content: '';
      position: absolute;
      top: -25%;
      left: -5%;
      width: 110%;
      height: 105%;
      background-color: var(--color-primary);
      transform: rotate(173deg);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      background: url(../images/downloads-banner.svg) no-repeat;
      background-size: 100% 100%;
      @media screen and (max-width: 767px) {
        background: url(../images/downloads-banner-responsive.svg) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  &-footer {
    width: 100%;
    position: relative;
    justify-content: space-between;
    flex-shrink: 0;
    z-index: 3;
    padding: 45px 36px;
    background-color: var(--color-primary);
    @media screen and (max-width: 767px) {
      padding: 40px 10px;
    }
    @media screen and (max-width: 374px) {
      padding: 40px 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30%;
      height: 100%;
      z-index: 1;

      background: url(../images/download-lines.svg) var(--color-primary)
        no-repeat;
      background-position: bottom left;
      background-size: cover;
    }
    &:after {
      content: '';
      position: absolute;
      top: -40%;
      right: 0;
      width: 20%;
      height: 125%;
      z-index: 1;
      background: url(../images/download-devices.png) no-repeat;
      background-position: right top;
      background-size: contain;
      @media screen and (max-width: 1100px) {
        display: none;
      }
    }
  }
  &-cta {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      flex-grow: 1;
      z-index: 3;
      flex-shrink: 0;
      width: 100%;
      @media screen and (min-width: 768px) {
        justify-content: center;
        margin: 0 auto;
      }
    }
    &-heading {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      margin: 5px 10px;
      &-text {
        font-size: 36px;
        font-weight: 800;
        color: var(--color-text-white);
        font-style: italic;
        text-align: center;
        line-height: 1em;
        text-transform: uppercase;
        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }
    }

    &-description {
      display: flex;
      justify-content: center;
      padding: 5px 14px;
      flex-shrink: 0;
      @media screen and (min-width: 768px) {
        width: 50%;
      }
      &-text {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.57em;
        color: var(--color-text-white);
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    &-buttons {
      display: flex;
      justify-content: center;
      padding: 25px 10px 0;
      flex-shrink: 0;
      width: 100%;
      @media screen and (max-width: 767px) {
        padding: 15px 0 0;
      }

      flex-wrap: wrap;
      &-item {
        margin: 5px 10px;
        @media screen and (max-width: 374px) {
          transform: scale(0.9);
          margin: 5px 0;
        }
      }
    }
    &-devices {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      @media screen and (min-width: 360px) {
        min-height: 140px;
      }
      @media screen and (min-width: 535px) {
        min-height: 200px;
      }
      @media screen and (min-width: 900px) {
        min-height: 250px;
      }
      @media screen and (min-width: 1200px) {
        min-height: 350px;
      }
      @media screen and (min-width: 1600px) {
        min-height: 450px;
      }
      @media screen and (min-width: 1800px) {
        min-height: 550px;
      }
      &-image {
        width: 78%;
        height: auto;
        pointer-events: none;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
  &-bullets {
    display: flex;
    flex-direction: column;
    width: 80%;
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0;
    }
    &-item {
      display: flex;
      margin: 75px 0;
      flex-direction: row;
      width: 100%;
      flex-shrink: 0;
      &.rtl {
        flex-direction: row-reverse;
        .downloads-bullets-item-section {
          align-items: flex-start;
          @media screen and (max-width: 767px) {
            align-items: center;
          }
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column !important;
        margin: 25px 0;
      }
      &-section {
        position: relative;
        display: flex;
        width: 50%;
        align-items: flex-end;
        padding: 0 15px;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 0;
          align-items: center;
        }
        &-info {
          pointer-events: none;
          display: flex;
          width: 100%;
          height: auto;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-shrink: 0;
          &-base {
            width: 100%;
          }
          &-graphic {
            position: absolute;
            width: 40%;
            height: auto;
            box-shadow: 5px -10px 20px 2px var(--color-shadow);
            @media screen and (max-width: 767) {
              width: 43%;
            }
            &.graphic {
              &-01 {
                top: 0;
                @media screen and (max-width: 767px) {
                  left: 18%;
                }
              }
              &-02 {
                right: 20%;
                top: 10%;
                @media screen and (max-width: 767px) {
                  right: 18%;
                }
              }
              &-03 {
                top: 0;
                @media screen and (max-width: 767px) {
                  left: 18%;
                }
              }
            }
          }
        }
        &-heading {
          width: 100%;
          position: relative;
          display: flex;
          flex-shrink: 0;
          margin-bottom: 16px;
          @media screen and (max-width: 767px) {
            padding: 0 20px;
          }
          &-text {
            color: var(--color-text-l2);
            font-size: 24px;
            font-weight: bold;
            text-align: left;
            position: absolute;
            bottom: 0;
            @media screen and (max-width: 767px) {
              font-size: 18px;
            }
          }
          &-index {
            line-height: 1em;
            font-size: 101px;
            color: var(--color-text-l2);
            opacity: 0.1;
            font-weight: bold;
            @media screen and (max-width: 767px) {
              font-size: 80px;
            }
          }
          &:after {
            content: '';
            height: 4px;
            width: 30px;
            background: var(--color-primary);
            position: absolute;
            bottom: -16px;
            border-radius: 16px;
          }
        }
        &-description {
          width: 100%;
          display: flex;
          flex-shrink: 0;
          margin: 16px 0 0;
          @media screen and (max-width: 767px) {
            margin: 16px 0 64px;
            padding: 0 20px;
          }
          &-text {
            color: var(--color-text-l2);
            font-size: 16px;
            line-height: 1.5;
            text-align: left;
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.dark {
  .downloads {
    &-footer {
      &:after {
        background-image: url(../images/dark/download-devices.png) !important;
      }
    }
  }
}
