.order-book {
  &-container {
    display: flex;
    position: relative;
    height: 100%;
    align-items: flex-start;
  }
  &-table {
    background-color: var(--color-background-l0);
    margin-bottom: 0;
    color: var(--color-text-l1);
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    position: relative;
    table-layout: fixed;
    &.empty {
      height: 200px;
      margin-bottom: 0;
      width: 100%;
      thead tr {
        height: 20px;
      }
    }
    &--open {
      width: 50%;
      thead {
        tr {
          th {
            width: 50%;
          }
        }
      }
    }

    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
    thead {
      tr {
        height: 20px;
        th {
          border-bottom: 1px solid var(--color-border);
          > h6 {
            height: 20px;
            color: var(--color-text-l3);
            font-weight: bold;
            font-size: 10px;
            line-height: 1.2;
            padding: 4px 14px;
            white-space: pre-line;
            vertical-align: middle;

            &.right {
              text-align: right;
            }
            @media screen and (max-width: 767px) {
              white-space: nowrap;
            }
            @media screen and (max-width: 992px) {
              padding: 4px 6px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        height: 30px;
        cursor: pointer;
        .filler {
          position: absolute;
          height: 30px;
        }
        td {
          font-size: 12px;
          padding: 2px 14px;
          border: none;
          font-weight: 600;
          line-height: 1.33;
          letter-spacing: 0.4px;
          max-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          white-space: nowrap;
          z-index: 5;
          position: relative;
          &.status-dot-cell {
            padding: 0;
            text-align: center !important;
            .dot {
              display: inline-block;
              height: 8px;
              width: 8px;
              border-radius: 50%;
              &.green {
                background-color: var(--color-green);
              }
              &.red {
                background-color: var(--color-red);
              }
            }
          }
          @media screen and (max-width: 992px) {
            padding: 2px 6px;
          }
        }
      }
      &.buy {
        tr {
          .filler {
            background: var(--color-table-row-green-overlay);
            right: 0;
            left: auto;
          }
          td {
            text-align: right;
            &:first-child {
              color: var(--color-green);
            }
            &:nth-child(2) {
              color: var(--color-text-l1);
            }
          }
          &:hover {
            td {
              background-color: var(--color-table-row-hover);
            }
          }
        }
      }
      &.sell {
        tr {
          .filler {
            background: var(--color-table-row-red-overlay);
            left: 0;
            right: auto;
          }
          td {
            text-align: left;
            &:first-child {
              color: var(--color-red);
            }
            &:nth-child(2) {
              color: var(--color-text-l1);
            }
          }
          &:hover {
            td {
              background-color: var(--color-table-row-hover);
            }
          }
        }
      }
    }
    &--xid {
      tbody {
        &.buy,
        &.sell {
          tr {
            .filler {
              left: 0;
              right: auto;
            }
            td {
              text-align: left;
            }
            .overlay {
              display: flex;
              position: absolute;
              left: 0;
              width: 100%;
              height: 30px;
              overflow: hidden;
              &__box {
                display: flex;
                position: absolute;
                right: -4%;
                opacity: 0;
                height: 100%;
                background: linear-gradient(
                  to right,
                  rgba(245, 248, 250, 0) 0%,
                  rgba(255, 255, 255, 0.27) 10%
                );
                width: 45%;
                justify-content: flex-end;
                min-width: 150px;
                align-items: center;
                transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                transition-delay: 0.3s;
              }
              &:hover {
                .overlay__box {
                  right: 0;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    &__loader {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 295px;
      justify-content: center;
      align-items: center;
    }
  }
}
