.my-orders {
  background-color: var(--color-background-l0);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  @media screen and (min-width: 768px) {
    box-shadow: 1px 1px 2px var(--color-shadow);
    &.my-orders__p2p {
      height: 320px;
    }
    &.my-orders__st {
      height: 322px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
  }
  &-toolbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px 8px;
    flex: 0 0 24px;
    box-shadow: 0 1px 0 0 var(--color-shadow);
    button {
      width: 60px;
      height: 20px;
      border-radius: 2px;
      border: solid 1px var(--color-divider);
      font-size: 10px;
      margin: 0;
      justify-content: center;
      color: var(--color-text-l2);
    }
  }
  &-pages {
    display: flex;
    padding: 2px 0;
    justify-content: center;
  }
  &-floating-btn {
    bottom: 10px;
    z-index: 2;

    &.prev {
      left: 15px;
    }
    &.next {
      right: 15px;
    }
    &.btn {
      padding: 6px 4px;
      opacity: 0.65;
      &:hover,
      &:focus {
        color: var(--color-white);
      }
      &:disabled {
        display: none;
      }
    }
  }
  &-table {
    height: calc(100% - 30px);
    flex-direction: column;
    width: 100%;
    position: relative;
    display: flex;
    z-index: 1;
    @media screen and (max-width: 767px) {
      height: 100%;
    }
    &-head {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      color: var(--color-text-l2);
      text-transform: uppercase;
      border-bottom: 1px solid var(--color-divider);
      flex: 0 0 20px;
      &-row {
        display: flex;
      }
      &-cell {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;

        height: 20px;
        color: var(--color-text-l2);
        font-weight: bold;
        font-size: 10px;
        line-height: 1.2;
        padding: 4px 14px;
        white-space: nowrap;
        &.pair {
          width: 20%;
          border-left: 5px solid transparent;
        }
        &.quantity {
          width: 25%;
          &.active {
            width: 45%;
            justify-content: flex-start;
          }
        }
        &.price {
          width: 20%;
        }
        &.status {
          width: 25%;
        }
        &.total {
          width: 20%;
        }
        &.info {
          width: 15%;
        }
        &.pending-actions {
          width: 35%;
          justify-content: flex-start;
        }
        &.action {
          width: 0%;
        }
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      width: 100%;
      &-loader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 270px;
        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        &-text {
          text-align: center;
          font-size: 12px;
          color: var(--color-text-l2);
          font-weight: 400;
          text-transform: none;
          padding: 10px 0;
        }
      }
      @media screen and (max-width: 767px) {
        overflow-y: auto;
        width: 100%;
      }
      &-row {
        display: flex;
        height: 45px;
        flex: 0 0 45px;
        position: relative;
        z-index: 1;
        justify-content: flex-start;
        cursor: pointer;
        overflow: hidden;
        &:before {
          content: '';
          width: 100%;
          height: 1px;
          background: var(--color-divider);
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: -1;
        }
        .filler {
          &.sell {
            background-color: var(--color-background-danger);
          }
          &.buy {
            background-color: var(--color-background-success);
          }
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
        }
        &.blinkGreen {
          animation: blinkGreen 1s ease-out 1;
        }

        &.blinkRed {
          animation: blinkRed 1s ease-out 1;
        }
        @media screen and (max-width: 767px) {
          &:last-child {
            margin-bottom: 65px;
          }
        }
        &:hover {
          .my-orders-table-body-tools {
            right: 0;
            opacity: 1;
            z-index: 2;
          }
        }
      }
      &-cell {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 1.2em;
        &-text {
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
        &-box {
          color: var(--color-text-l3);
          font-weight: 700;
          line-height: 1.2em;
          position: relative;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          &.active {
            &.buy {
              background: var(--color-green);
            }
            &.sell {
              background: var(--color-red);
            }
            color: var(--color-white);
          }
        }
        .subtext {
          color: var(--color-text-l2);
          font-size: 10px;
          display: block;
          color: var(--color-text-l2);
          font-weight: bold;
        }
        .underline {
          border-bottom: 1px solid var(--color-divider);
          display: inline-block;
          margin-bottom: 1px;
          color: var(--color-text-l2);
          font-weight: bold;
        }
        &.pair {
          width: 20%;

          border-left: 5px solid;
          &.sell {
            border-color: var(--color-red);
          }
          &.buy {
            border-color: var(--color-green);
          }
        }
        &.quantity {
          width: 25%;
          &.active {
            width: 45%;
            align-items: flex-start;
            .my-orders-table-body-cell-text {
              text-align: left;
              padding: 0 16px;
            }
          }
        }
        &.price {
          width: 20%;
        }
        &.status {
          width: 25%;
        }
        &.total {
          width: 20%;
        }
        &.info {
          width: 15%;
          align-items: flex-end;
          margin-left: auto;
        }
        &.action {
          width: 10%;
          border-right: 5px solid transparent;
          font-size: 18px;
          color: var(--color-text-l1);
          &.active {
            .mdi {
              animation: bounceInLeft 1s infinite;
            }
          }

          &.inactive {
            color: var(--color-text-l3);
          }

          &:hover {
            color: var(--color-primary);
          }
        }
        &.pending-actions {
          width: 25%;
        }
      }
      &-tools {
        display: flex;
        height: 100%;
        width: 85%;
        opacity: 0;
        z-index: -1;
        background: linear-gradient(
          to right,
          rgba(245, 248, 250, 0) 0%,
          #f5f8fa 10%
        );
        position: absolute;
        right: -10px;
        top: 0;
        transition: 0.2s all;
        justify-content: flex-end;
        align-items: center;
        font-weight: 600 !important;
        color: var(--color-text-l2);
        cursor: default;
        &-time {
          font-size: 10px;
          line-height: 1.2;
          padding: 0 12px;
        }
        &-status {
          padding: 0 12px 0 0;
          font-size: 10px;
          line-height: 1.2;
          font-weight: bold;
        }
        &-item {
          display: flex;
          flex: 0 0 48px;
          height: 100%;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          cursor: pointer;
          transition: 0.2s all;
          &.xl {
            flex: 0 0 96px;
          }
          &.disabled {
            opacity: 0;
          }
          &:hover {
            color: var(--color-text-l1);
          }
        }
      }
    }
  }
}
