.key-manager {
  > .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    > .pane {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      box-shadow: 0 2px 4px 0 var(--color-shadow);
      background: var(--color-background-l0);
      overflow-y: auto;
      border-radius: 4px;
      margin-bottom: 10px;
      > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 18px;
        border-bottom: 1px solid var(--color-divider);
        > .title {
          font-size: 16px;
          font-weight: bold;
        }
      }

      > .description {
        padding: 16px 18px;
      }
      > .cta {
        padding: 0 18px 16px;
      }

      .list {
        &-group {
          margin: 16px;
          border-radius: 2px;
        }
        &-item {
          border: 1px solid var(--color-divider);
          border-radius: 4px;
          margin: 8px 0;
          &:first-child {
            margin-bottom: 8px;
            margin-top: 0;
          }
          &:last-child {
            margin-top: 8px;
            margin-bottom: 0;
          }

          &-title {
            a {
              display: flex;
              width: 100%;
              font-weight: bold;
              flex-shrink: 0;
              color: var(--color-text-l1);
              padding: 16px 18px;
              font-size: 14px;
              justify-content: space-between;
            }
          }
          &-body {
            display: flex;
            width: 100%;
            flex-shrink: 0;
            padding: 16px 18px;
            border-top: 1px solid var(--color-divider);
          }

          &-container {
            &-section {
              > .permissions {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                grid-column-gap: 60px;
                grid-row-gap: 16px;
              }
            }
          }
        }
      }
    }
  }
}
