.dropzone {
  cursor: pointer;
  min-height: 98px;
  margin-bottom: 16px;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  border: dashed 1px var(--color-divider);
  position: relative;
  transition: 0.3s all;
  &:hover {
    border-color: 1px var(--color-divider);
  }
  &:focus,
  &:active {
    border-color: 1px var(--color-primary);
    .file-upload-icon {
      box-shadow: inset 1px 1px 2px 0px 1px var(--color-shadow);
    }
  }
  .note {
    position: absolute;
    bottom: 5px;
    left: 15px;
    font-size: 10px;
    margin-top: 8px;
    font-weight: bold;
  }
  .file-upload-icon {
    display: flex;
    font-size: 40px;
    height: 64px;
    width: 64px;
    justify-content: center;
    align-items: center;
    background: 1px var(--color-background-l1);
    color: 1px var(--color-text-l2);
    border-radius: 2px;
    padding: 8px;
    overflow: hidden;
    border: 1px solid 1px var(--color-divider);
    box-shadow: 1px 1px 2px 0px 1px var(--color-shadow);
    &:focus,
    &:active {
      box-shadow: inset 1px 1px 2px 0px 1px var(--color-divider);
    }
  }
}
