.fees {
  display: inline-flex;
  flex-direction: column;
  background-color: var(--color-background-default);
  flex-shrink: 0;
  width: 100%;
  &-section {
    display: inline-flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 75%;
    padding: 0 36px;
    @media screen and (max-width: 767px) {
      padding: 0 15px;
      width: 100%;
    }
  }
  &-title {
    font-weight: 700;
    padding: 45px 0 20px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.5px;
    align-self: flex-start;
    @media screen and (max-width: 767px) {
      padding: 20px 0 8px;
      font-size: 14px;
    }
  }
  @mixin info-layout($justify) {
    display: flex;
    flex-direction: column;
    justify-content: $justify;
    align-items: center;
    max-width: 1200px;
    width: 100%;
  }
  &-info {
    position: relative;
    width: 100%;
    &-title {
      @include info-layout(left);
    }
    &-wrapper {
      z-index: 2;
      @include info-layout(center);
      .table-responsive {
        overflow-x: unset;
        border: 1px solid var(--color-divider);
        @media only screen and (max-width: 767) {
          border: 1px solid var(--color-divider);
        }
        @media only screen and (max-width: 991px) {
          height: 65vh;
          overflow-y: auto;
          margin-bottom: 0;
          border: 1px solid var(--color-divider);
        }
      }
    }
    &-toggle {
      width: 100%;
      &-group {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        @media screen and (max-width: 767px) {
          margin-bottom: 12px;
        }
      }
      &-group-parent {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        align-self: start;
        border-bottom: 2px solid var(--color-divider);
        @media screen and (max-width: 767px) {
          margin-bottom: 12px;
          width: 100%;
        }
      }
      @mixin toggle-group-item($fontSize) {
        font-size: $fontSize;
        font-weight: 600;
        box-shadow: none !important;
        opacity: 0.7;
        color: var(--color-text-l1);
        position: relative;
        @media screen and (max-width: 767px) {
          font-size: 11px;
        }
        &:hover {
          color: var(--color-text-l1);
        }
        &.active {
          opacity: 1;
          font-weight: 700;
          color: var(--color-primary);
          &:after {
            content: '';
            height: 4px;
            width: 100%;
            display: block;
            background: var(--color-primary);
            position: absolute;
            left: 0;
            border-radius: 16px;
            margin-top: 5px;
          }
        }
      }
      &-item {
        flex-grow: 1;
        @include toggle-group-item(13px);
      }
      &-item-parent {
        width: 30%;
        text-transform: uppercase;
        @include toggle-group-item(14px);
        @media screen and (max-width: 767px) {
          width: 60%;
        }
      }
    }

    &-table {
      width: 100%;
      table {
        margin-bottom: 0 !important;
        border: 1px solid var(--color-divider);
        border-collapse: separate;
        > thead {
          position: sticky;
          top: 0;
        }
        > thead > tr,
        > tbody > tr {
          background-color: var(--color-table-row-odd);
          &.total {
            background-color: var(--color-green);
            color: var(--color-white);
          }
          &.even-child {
            background-color: var(--color-table-row-even);
          }
          &.last-child {
            border-bottom: 1px solid var(--color-divider);
          }
          > th,
          > td {
            padding: 8px 15px;
            font-size: 12px;
            @media screen and (max-width: 767px) {
              padding: 8px 14px;
              font-size: 11px;
            }
            span.tag-green {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              width: 100%;
              background: var(--color-background-success);
              color: var(--color-green);
              padding: 4px 8px;
              height: 25px;
              border-radius: 4px;
              line-height: 1em;
              text-transform: capitalize;
            }

            span.tag-red {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              width: 100%;
              color: var(--color-red);
              background: var(--color-background-danger);
              padding: 4px 8px;
              height: 25px;
              border-radius: 4px;
              line-height: 1em;
              text-transform: capitalize;
            }

            a.rapid {
              line-height: 1em;
              text-align: center;
              color: var(--color-primary);
              background: var(--color-background-hover);
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              width: 100%;
              padding: 4px 8px;
              height: 25px;
              border-radius: 4px;
              font-size: 12px;
              text-transform: uppercase;
              font-weight: bold;
              letter-spacing: 0.3px;
              &:hover {
                border: 1px solid var(--color-primary);
              }
            }
          }
          > th {
            color: var(--color-text-l2);
            text-transform: uppercase;
            border-top: 0;
            box-shadow: inset 1px 0px 0px 0px var(--color-divider);
            vertical-align: middle;
            text-align: center;
            background-color: var(--color-background-l0);
          }
        }
      }
    }
  }
  &-bottom-note {
    display: flex;
    padding: 0 4px 45px;
    width: 100%;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      padding: 0 4px 25px;
    }
  }
  &-faq {
    background: var(--color-background-l0);
    width: 100%;
    display: flex;
    &-wrapper {
      width: 100%;
      max-width: 650px;
      margin: 48px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 0;
    }
  }
}
