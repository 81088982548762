.feature-checklist {
  display: inline-flex;
  width: 100%;
  background: var(--color-background-l0);
  margin-top: 24px;
  justify-content: space-between;
  &-section {
    display: flex;
    flex-direction: column;
    width: 50%;
    &-title {
      padding: 16px;
      font-weight: 700;
      white-space: nowrap;
      text-align: center;
      letter-spacing: 0.5px;
      font-size: 13px;
      line-height: 1.2em;
      background: var(--color-background-l1);
      border-bottom: 1px solid var(--color-divider);
    }
    &-list {
      padding: 16px 0 16px 12%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0;
    }
    &-item {
      display: grid;
      grid-template-columns: 12px auto;
      align-items: center;
      padding: 4px 0;
      &-text {
        padding: 0 8px;
        &.disabled {
          color: var(--color-text-l3);
        }
      }
    }
    &-button {
      display: flex;
      padding: 8px;
      justify-content: center;
    }
    &.partial {
      border-right: 1px solid var(--color-divider);
    }
  }
}
