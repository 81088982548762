.activity-logs {
  &-panel {
    background-color: var(--color-background-l0);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 var(--color-shadow);
    position: relative;

    @media screen and (max-width: 420px) {
      margin-top: 40px;
      overflow: visible;
    }
    &-loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 150px;
      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      &-text {
        text-align: center;
        font-size: 12px;
        color: var(--color-text-l2);
        font-weight: 400;
        text-transform: none;
      }
    }
  }
  &-list {
    &.loading {
      position: relative;
      &:before {
        content: '';
        background: var(--color-background-l1);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      &:after {
        content: '';
        margin-top: -16px;
        margin-left: -16px;
        position: absolute;
        top: 50%;
        left: 50%;
        border: 2px solid var(--color-primary);
        border-top-color: var(--color-divider) !important;
        border-left-color: var(--color-divider) !important;
        width: 32px;
        background: none !important;
        height: 32px;
        border-radius: 50%;
        animation: loadingSpinner 0.7s infinite linear;
        z-index: 3;
      }
    }
  }
  &-row {
    padding: 18px;
    border-top: 1px solid var(--color-divider);
    display: flex;
    flex-direction: column;
    &:hover {
      background: var(--color-background-l1);
    }
  }
  &-cell {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 4px 0;
    &-label {
      font-size: 12px;
      color: var(--color-text-l2);
      text-transform: uppercase;
      font-weight: 700;
      width: 20%;
      min-width: 80px;
      letter-spacing: 0.5px;
    }
    &-text {
      font-size: 12px;
      color: var(--color-text-l1);
      font-weight: 700;
      padding-left: 4px;
      width: 80%;
      letter-spacing: 0.5px;
      white-space: pre-wrap;
      word-break: break-word;
    }
    &.activity .activity-logs-cell-text {
      color: var(--color-primary);
    }
  }
  &-pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--color-background-l0);
    z-index: 2;
    @media screen and (max-width: 420px) {
      top: -40px;
      left: 50%;
      right: 50%;
    }
    &-wrapper {
      display: flex;
      border-radius: 4px;
    }
    &-button {
      all: unset;
      padding: 4px 10px;
      font-size: 16px;
      color: var(--color-primary);
      background-color: var(--color-background-l2);
      &.prev {
        border-radius: 4px 0 0 4px;
      }
      &.next {
        border-radius: 0 4px 4px 0;
      }
      &:hover {
        background-color: var(--color-primary);
        color: var(--color-white);
        cursor: pointer;
        border-color: var(--color-primary);
      }
      &:disabled {
        opacity: 0.1;
      }
    }
    &-label {
      padding: 4px 10px;
      border-width: 0 1px;
      border-style: solid;
      border-color: var(--color-primary);
      background: var(--color-primary);
      font-weight: 700;
      color: var(--color-white);
    }
  }
}
