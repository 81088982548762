.matching-box {
  margin: 0 auto 8px;
  background: var(--color-background-l0);
  padding: 32px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 2px var(--color-shadow);
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 24px 16px 16px;
  }
  &-title {
    padding: 8px 0;
    text-align: center;
    span {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        font-weight: 800;
      }
    }
  }
  &-order {
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
    &-label {
      font-size: 12px;
      color: var(--color-text-l2);
    }
  }
  &-price {
    display: flex;
    width: 75%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid var(--color-divider);
    text-align: center;
    @media screen and (max-width: 767px) {
      width: 90%;
    }
    span {
      font-size: 14px;
      color: var(--color-text-l1);
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
  &-description {
    display: flex;
    width: 85%;
    align-items: center;
    flex-direction: column;
    padding: 16px 0 0 0;
    &-text {
      font-size: 12px;
      color: var(--color-text-l2);
      text-align: center;
    }
    &-timestamp {
      text-align: center;
      font-size: 12px;
      padding-top: 8px;
      color: var(--color-text-l2);
    }
  }
  &-cancel {
    top: 0;
    right: 0;
    color: var(--color-red);
    &:hover {
      background-color: var(--color-red);
      .matching-box-status-cancel-text {
        color: var(--color-white);
      }
    }
    &-text {
      font-size: 12px;
      color: var(--color-red);
    }
  }
  &-nav {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    &-link {
      font-size: 14px;
      color: var(--color-text-l2);
      cursor: pointer;
      &:hover {
        color: var(--color-text-l1);
      }
    }
  }
  .box {
    padding: 16px 0;
  }
}
