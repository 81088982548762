table.order-history {
  background-color: var(--color-background-l0);
  margin-bottom: 0;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  thead {
    border-top: 1px solid var(--color-divider);
    tr {
      th {
        color: var(--color-text-l3);
        font-size: 10px;
        line-height: 1.2;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid var(--color-divider);
        padding: 4px;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 2px 0;
        font-size: 12px;
        line-height: 1.67;
        -webkit-letter-spacing: 0.4px;
        -moz-letter-spacing: 0.4px;
        -ms-letter-spacing: 0.4px;
        letter-spacing: 0.4px;
        color: var(--color-text-l2);
        font-weight: 400;

        .subtext {
          color: var(--color-text-l2);
          font-size: 10px;
        }
        &.hero-marker {
          font-weight: bold;
          &.sell {
            color: var(--color-red);
          }
          &.buy {
            color: var(--color-green);
          }
        }
      }
    }
  }
}
