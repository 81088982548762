.settings {
  display: flex;
  flex-direction: column;
  &-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    max-width: 1000px;
    width: 75%;
    margin: 50px auto;
    @media screen and (max-width: 1199px) {
      width: 85%;
    }
    @media screen and (max-width: 991px) {
      width: auto;
      margin: 20px 10px 0 20px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0 auto;
    }
    &.without-header {
      margin: 10px auto;
    }
  }
  &-sidebar {
    display: flex;
    width: 300px;
    flex-direction: column;
    z-index: 3;
    transition: all 0.2s ease-in;
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 10px;
    }
    &-heading {
      padding: 16px 2px;
      color: var(--color-text-l1);
      font-weight: 700;
      font-size: 18px;
      line-height: 1em;
      &-title {
        font-size: 24px;
        font-weight: 700;
      }
    }

    &-nav {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 4px 0 var(--color-shadow);
      @media screen and (max-width: 767px) {
        overflow: auto;
        margin-bottom: 20px;
      }
      &-item {
        display: flex;
        padding: 16px 18px;
        justify-content: space-between;
        background-color: var(--color-background-l0);
        align-items: center;
        cursor: pointer;
        box-shadow: inset 0 1px 0 0 var(--color-shadow);
        position: relative;
        flex-shrink: 0;
        &.selected {
          &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 2px;
            background-color: var(--color-primary);
            top: 1px;
            left: 0px;
            bottom: 1px;
          }
        }
        &.selected,
        &:hover {
          .settings-sidebar-nav-item-title {
            color: var(--color-text-l1);
          }
        }

        &-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-title {
          padding-left: 18px;
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: 0.7px;
          text-transform: uppercase;
          font-weight: bold;
          color: var(--color-text-l1);
          .mdi {
            color: var(--color-primary);
          }
        }
      }
    }
  }
  &-container {
    display: flex;
    flex-grow: 1;
    min-height: 100%;
    margin: 0 10px 10px;
  }
  &-section {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &-container {
      display: flex;
      flex-shrink: 0;
    }

    &-header {
      padding: 15px 0;
      color: var(--color-text-l1);
      font-weight: 700;
      font-size: 18px;
      line-height: 1em;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      &-back {
        padding-right: 12px;
        padding-left: 2px;
      }
      &-title {
        padding: 0 15px;
      }
    }
    &-body {
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
      &-panel {
        background-color: var(--color-background-l0);
        border-radius: 4px;
        padding: 16px 18px;
        box-shadow: 0 2px 4px 0 var(--color-shadow);
        position: relative;
        overflow: auto;
        display: flex;
        flex-direction: column;
        &-overflow-visible {
          overflow: visible;
        }
      }
      &-panelnopadding {
        background-color: var(--color-background-l0);
        border-radius: 4px;
        padding: 16px 0px;
        box-shadow: 0 2px 4px 0 var(--color-shadow);
        position: relative;
        overflow: auto;
        display: flex;
        flex-direction: column;
        &-overflow-visible {
          overflow: visible;
        }
      }
      &-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--color-divider);
      }
      &-sub {
        padding: 0 0 8px 0;
      }
      &-list {
        margin-left: 20px;
        li {
          list-style-type: none;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            height: 2px;
            width: 8px;
            bottom: calc(50% - 1px);
            background: var(--color-primary);
            left: -15px;
          }
        }
      }
    }
  }
}
