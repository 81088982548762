.responsive-tab {
  &-container {
    display: flex;
    white-space: nowrap;
    background: var(--color-background-l0);
  }
  &-list {
    display: flex;
    height: 55px;
    position: absolute;
    width: 100%;
    bottom: 0;
    box-shadow: 1px -1px 2px var(--color-shadow);
    z-index: 9;
    background: var(--color-background-l0);
    &-above-popup {
      z-index: 100; //so that tabs come above the popup
    }
    &-item {
      display: flex;
      background: var(--color-background-l0);
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      color: var(--color-text-l2);
      font-size: 12px;
      flex-direction: column;

      // Tab links should not be selectable
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.selected {
        color: var(--color-red);
      }
      &-icon {
        font-size: 20px;
      }
      &-wrx {
        padding: 10px;
        border-radius: 50%;
        background: var(--color-primary);
        display: flex;
        justify-content: center;
        align-content: center;
        margin-top: -20px;
        margin-bottom: 8px;
      }
      &.pending-actions {
        &:after {
          content: '';
          height: 10px;
          width: 10px;
          position: absolute;
          border-radius: 50%;
          background: var(--color-red);
          top: 10px;
          right: 10px;
          animation: flash 3s cubic-bezier(0.4, 0, 1, 1) 1s infinite;
        }
      }
    }
  }
  &-panel {
    background: var(--color-background-l0);
    height: auto;
    &__st {
      background-color: var(--color-background-l1);
    }
    &__with-gutter {
      margin: 0 12px;
    }
    &-item {
      display: flex;
      display: inline-flex;
      flex-direction: column;
      white-space: normal;
      flex-shrink: 0;
      flex-grow: 1;
      width: 100%;
      .trade-history tbody {
        @media screen and (max-width: 767px) {
          display: block;
          // height: 100%;
          height: auto; //issue with resposive table. 100% height makes it tak 0px as the height.
          overflow-y: auto;
        }
      }
    }
  }
}
