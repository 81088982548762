.email-approval {
  &-panel {
    margin: 0 10px;
  }
  &-body {
    position: relative;
    box-shadow: 0 2px 4px 0 var(--color-shadow);
    background: var(--color-background-l0);
    overflow-y: auto;
    border-radius: 4px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    max-width: 500px;
    width: 75%;
    margin: 50px auto 0;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 25px auto 0;
    }
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 24px;
      min-height: 175px;
      text-align: center;
      img {
        margin-bottom: 8px;
      }
    }
    &-description {
      font-weight: 700;
    }
    &-heading {
      padding: 8px 24px;
      border-bottom: 1px solid var(--color-divider);
      &-text {
        line-height: 1.3em;
        letter-spacing: 0.5px;
      }
    }
  }
}
