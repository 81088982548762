.errorPage{
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  &-title{
    font-size: 24px;
    font-weight: bold;
    line-height: 1.67;
    color: #161616;
  }
  &-description{
    font-size: 16px;
    letter-spacing: 0.45px;
    color: #161616;
  }
  &-button{
    all: unset;
    background: #3067f0;
    color: #fff;
    padding: 8px 36px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    margin: 24px 0;
    cursor: pointer;
    border: 1px solid #3b6ce8;
  }
}