.transact-history {
  &-container {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 1px 2px 0 var(--color-shadow);
    border: 1px solid var(--color-divider);
  }
  &-table {
    background-color: var(--color-background-l0);
    margin-bottom: 0px;

    thead > tr {
      th {
        color: var(--color-text-l1);
        font-size: 10px;
        line-height: 1.2;
        font-weight: bold;
        padding: 8px 14px !important;
        border-bottom: 1px solid var(--color-divider);
        text-transform: uppercase;
      }
    }
    tbody > tr {
      &:nth-child(even) {
        background: var(--color-table-row-even);
      }
      &:nth-child(odd) {
        background: var(--color-table-row-odd);
      }
      &:hover {
        background: var(--color-table-row-hover);
        cursor: pointer;
      }

      td {
        .rupee-symbol {
          font-size: 22px;
          color: var(--color-text-l2);
        }
        &:first-child {
          padding: 2px 2px 2px 14px;
          width: 35px;
        }
        font-size: 12px;
        vertical-align: middle;
        height: 48px;
        color: var(--color-text-l1);
        &:last-child {
          color: var(--color-text-l1);
        }
        &.deposit,
        &.green {
          color: var(--color-green);
        }
        &.withdraw,
        &.red {
          color: var(--color-red);
        }
      }
    }
  }
}
