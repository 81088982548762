.userJourney {
  height: 100%;
  position: static;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  &-indicator {
    &-strip {
      display: flex;
      align-items: center;
      background: var(--color-background-l0);
      padding: 35px 0 20px;
    }
    list-style-type: none;
    display: table;
    table-layout: fixed;
    width: 100%;
    &-step {
      text-align: center;
      display: table-cell;
      position: relative;
      color: var(--color-text-l1);
      font-weight: bold;
      z-index: 1;
      &:before {
        content: '';
        display: block;
        margin: 0 auto 4px;
        background-color: var(--color-primary);
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-weight: bold;
        border: 6px solid var(--color-divider);
        border-radius: 50%;
      }
      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: var(--color-primary);
        position: absolute;
        top: 11.5px;
        left: 50%;
        z-index: -1;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.is-active {
        &:before {
          background-color: var(--color-primary);
        }
        &:after {
          background-color: var(--color-divider);
        }
        ~ li {
          &:before {
            background-color: var(--color-gray);
            border-color: var(--color-divider);
          }
          &:after {
            background-color: var(--color-divider);
          }
        }
      }
    }
  }
}
