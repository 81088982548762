.dhamaka {
  display: inline-flex;
  flex-direction: column;
  background-color: var(--color-background-l0);
  flex-shrink: 0;
  width: 100%;
  &-section {
    display: inline-flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 75%;
    padding: 0 36px;
    @media screen and (max-width: 767px) {
      padding: 0 15px;
      width: 100%;
    }
  }
  &-hero {
    width: 100%;
    position: relative;
    height: 540px;
    justify-content: space-between;
    background-color: var(--color-background-l0);
    @media screen and (max-width: 767px) {
      height: 590px;
    }
    &.opted-in {
      background-color: var(--color-background-l1);
    }
    &.logged-in {
      @media screen and (max-width: 767px) {
        height: 500px;
      }
    }
    z-index: 3;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: url(../images/dhamaka-banner-pattern.svg) no-repeat;
      background-size: 100% 100%;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      background: url(../images/dhamaka-banner-design-desktop.png) no-repeat;
      @media screen and (max-width: 767px) {
        background: url(../images/dhamaka-banner-design-responsive.png)
          no-repeat;
        background-size: 100%;
      }
      background-size: 100%;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      margin: 0 auto 64px;
      flex-grow: 1;
      z-index: 3;
      flex-shrink: 0;
      @media screen and (min-width: 768px) {
        justify-content: center;
        margin: 0 auto 48px;
      }
    }

    &-coin-logo {
      width: 320px;
      height: 150px;
      margin: 36px 0;
      @media screen and (max-width: 767px) {
        height: 100px;
        margin: 30px 0;
      }
    }

    &-heading {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      &-title {
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
        color: var(--color-white);
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }
    }

    &-description {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      padding: 0 14px;
      @media screen and (min-width: 768px) {
        width: 80%;
      }
      &-title {
        font-size: 18px;
        font-weight: 700;
        opacity: 0.8;
        line-height: 20px;
        color: var(--color-white);
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          opacity: 1;
        }
      }
    }
  }
  &-mininfo {
    &-desc {
      z-index: 3;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      width: 100%;
      margin-top: -64px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: -48px;
      }
      &-wrapped {
        width: 75%;
        max-width: 650px;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      &-box {
        display: flex;
        flex-direction: column;
        width: 30%;
        //height: 160px;
        background-color: var(--color-background-l0);
        border-radius: 8px;
        box-shadow: 0px 30px 35px -18px var(--color-shadow);
        position: relative;
        overflow: hidden;
        padding: 20px;
        flex-shrink: 0;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 14px;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          box-shadow: 0px 15px 20px -10px var(--color-shadow);
        }
        .title {
          font-size: 24px;
          font-weight: 700;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
        .description {
          width: 80%;
          opacity: 0.5;
          font-size: 14px;
          flex-grow: 1;
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
          &.uppercase {
            text-transform: uppercase;
          }
        }
        .box-icon {
          position: absolute;
          bottom: 0;
          height: 51px;
          right: 0;
          width: 51px;
        }
        &-success {
          width: 48%;
          border-left: 8px solid var(--color-green);
          border-radius: 5px;
          align-items: center;
          padding: 20px 40px 20px 20px;
          @media screen and (max-width: 767px) {
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 20px;
            }
            box-shadow: 0px 15px 20px -10px var(--color-shadow);
          }
          .description {
            font-weight: 700;
            text-transform: uppercase;
            width: 100%;
            text-align: center;
          }
          .title {
            font-size: 28px;
          }
        }
        &-error {
          width: 100%;
          border-left: 8px solid var(--color-red);
          border-radius: 5px;
          padding: 45px;
          .title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: var(--color-red);
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 24px;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            box-shadow: 0px 15px 20px -10px var(--color-shadow);
          }
        }
      }
    }
  }
  &-calculator {
    position: relative;
    width: 100%;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: url(../images/dhamaka-calculator-background-desktop.svg)
        no-repeat;
      background-size: 100% 100%;
      @media screen and (max-width: 767px) {
        background: url(../images/dhamaka-calculator-background-responsive.svg)
          no-repeat;
        background-size: 100% 100%;
      }
    }
    &-wrapper {
      z-index: 2;
      width: 100%;
      max-width: 650px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin: 64px auto 128px;
      @media screen and (max-width: 767px) {
        margin: 48px auto 96px;
      }
    }

    &-box {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      background-color: var(--color-background-l0);
      padding: 30px;
      width: 100%;
      box-shadow: 0 0 15px 0 var(--color-shadow);
      border-radius: 4px;
      @media screen and (max-width: 767px) {
        padding: 25px;
      }
      &-heading {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        padding: 0 0 36px 0;
        @media screen and (max-width: 767px) {
          padding: 0 0 24px 0;
        }
        &-icon {
          padding: 0 8px;
          @media screen and (max-width: 767px) {
            padding: 0 4px;
            height: 20px;
          }
        }
        &-title {
          font-size: 28px;
          line-height: 20px;
          color: var(--color-primary);
          font-weight: 700;
          padding: 0 8px;
          text-align: center;
          @media screen and (max-width: 767px) {
            padding: 0 4px;
            font-size: 18px;
          }
        }
      }
      &-input {
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        &-volume {
          width: calc(50% - 16px);
          //flex-grow: 1;
          //padding: 0 0 0 16px;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        &-currency {
          //flex-grow: 1;
          //padding: 0 16px 0 0;
          width: calc(50% - 16px);
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          &-textbox {
            border-radius: 4px;
            overflow: hidden;
            font-weight: 600;
            line-height: 46px;
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
            font-size: 14px;
            -webkit-letter-spacing: 0.2px;
            -moz-letter-spacing: 0.2px;
            -ms-letter-spacing: 0.2px;
            letter-spacing: 0.2px;
            border: 1px solid var(--color-divider);
            color: var(--color-text-l2);
            -webkit-transition: all 0.4s;
            transition: all 0.4s;
            &:focus {
              background-color: var(--color-background-l0);
              border: solid 1px var(--color-primary);
              outline: 0;
              border-radius: 4px;
            }
          }
        }
      }
      &-bonus {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        &-heading {
          display: flex;
          flex-shrink: 0;
          justify-content: center;
          &-title {
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1.5px;
            color: var(--color-text-l1);
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
          }
        }
        &-value {
          border-radius: 4px;
          background-color: var(--color-background-l1);
          display: flex;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          margin: 10px 0;
          padding: 14px;
          &-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 0.75;
            color: var(--color-text-l2);
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.2em;
          }
        }
        &-inr {
          display: flex;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          &-title {
            font-size: 14px;
            font-weight: 600;
            line-height: 12px;
            letter-spacing: 1.75px;
            color: var(--color-text-l1);
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  &-faq {
    background: var(--color-background-l0);
    width: 100%;
    display: flex;
    flex-shrink: 0;
    &-wrapper {
      width: 100%;
      max-width: 650px;
      margin: 64px auto;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 0;
      @media screen and (max-width: 767px) {
        margin: 48px auto;
      }
    }
    &-help {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      text-align: center;
      padding: 16px 0;
      &,
      a {
        font-size: 16px;
      }
      @media screen and (max-width: 767px) {
        padding: 8px 0;
        &,
        a {
          font-size: 14px;
        }
      }
    }
    &-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      &-heading {
        display: flex;
        justify-content: center;
        padding: 8px 0;
        flex-shrink: 0;
        &-title {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        }
      }
      &-panel {
        &-group {
          width: 100%;
        }
        &-item {
          border-radius: 4px;
          border: solid 1px var(--color-divider);
          margin: 5px 0;
          &-title a {
            display: flex;
            padding: 25px;
            flex-grow: 1;
            justify-content: space-between;
            color: var(--color-text-link);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            flex-shrink: 0;
          }
          &-body {
            font-size: 14px;
            padding: 0 25px 25px;
            color: var(--color-text-l2);
            line-height: 20px;
          }
        }
      }
    }
  }
  &-history {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 30px;
    background-color: var(--color-background-l1);
    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }
    &-wrapper {
      width: 75%;
      max-width: 800px;
      margin: 64px auto 0;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        margin: 48px auto 0;
        width: 100%;
      }
    }
    &-label {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      margin-top: 16px;
      @media screen and (max-width: 767px) {
        margin-top: 8px;
      }
      &-text {
        font-size: 12px;
        color: var(--color-text-l2);
        text-align: center;
      }
    }
    &-title {
      font-size: 16px;
      font-weight: 700;
      color: black;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin-bottom: 24px;
      text-align: center;
      @media screen and (max-width: 767px) {
        font-size: 15px;
        margin-bottom: 16px;
      }
    }
    &-table {
      width: 100%;
      border-radius: 4px;
      margin-bottom: 0;
      @media screen and (min-width: 768px) {
        overflow: hidden;
      }
      table {
        margin-bottom: 0 !important;
        > thead > tr,
        > tbody > tr {
          background-color: var(--color-table-row-odd);
          &:not(:last-child) {
            border-bottom: 1px solid var(--color-divider);
          }
          &:nth-child(even) {
            background-color: var(--color-table-row-even);
          }
          &.total {
            background-color: var(--color-green);
            color: var(--color-white);
            font-weight: bold;
          }
        }
        > thead > tr > th,
        > thead > tr > td,
        > tbody > tr > td {
          padding: 15px 20px;

          font-size: 14px;
          @media screen and (max-width: 767px) {
            padding: 8px 14px;
            font-size: 11px;
          }
        }
        > thead > th {
          color: var(--color-text-l3);
          text-transform: uppercase;
        }
      }
    }
  }
}
