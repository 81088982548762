.trade-history {
  background-color: var(--color-background-l0);
  color: var(--color-text-l1);
  margin-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 767px) {
    overflow-y: auto;
  }
  thead {
    tr {
      @media screen and (max-width: 767px) {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
      th > h6 {
        height: 20px;
        color: var(--color-text-l3);
        font-weight: bold;
        font-size: 10px;
        line-height: 1.2;
        border-bottom: 1px solid var(--color-border);
        padding: 4px 14px;
        text-align: center;
        text-transform: uppercase;
        &.right {
          text-align: right;
        }
      }
    }
  }
  tbody {
    & > tr {
      @media screen and (max-width: 767px) {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
      height: 30px;
      &:hover {
        cursor: pointer;
        td {
          background-color: var(--color-table-row-hover);
        }
      }
      & > td {
        vertical-align: middle;
        padding: 2px 4px !important;
        font-size: 12px;
        letter-spacing: 0.4px;
        font-weight: 600;
        line-height: 1.33;
        text-align: center;
        position: relative;
        z-index: 5;
      }
      &.fadeInGreen {
        animation: fadeInGreen 1s ease-out 1;
        background-color: var(--color-table-row-green-overlay);
        td:first-child {
          color: var(--color-green);
        }
      }

      &.fadeInRed {
        animation: fadeInRed 1s ease-out 1;
        background-color: var(--color-table-row-red-overlay);
        td:first-child {
          color: var(--color-red);
        }
      }
    }
  }
}
